import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'app/rootReducer';
import { useHistory } from 'react-router';
import appRoutes from 'app/appRoutes';

import { makeStyles } from '@material-ui/core/styles';
import { Select, MenuItem, TextField, FormControl, Button, CircularProgress, FormHelperText } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import Colors from 'Colors';

import { AmountInputFormat } from 'utils/NumberFormatUtils';

import AcquisitionModel from 'models/AcquisitionModel';
import { fetchAllListingItems, submitAcquisistion } from './tradeApi';
import { setAcquisition } from './tradeSlice';
import IListing from 'interfaces/IListing';
import HttpResponseMessages from 'features/errorHandling/HttpResponseMessages';
import { clearErrorMessage } from 'features/errorHandling/errorHandlingSlice';

interface Props {
    heading: string,
    companyPin: string,
    emailAddress: string,
    isMobilePhone: boolean
}
export default function Acquisition({ heading, companyPin, emailAddress, isMobilePhone }: Props) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const history = useHistory();

    const [selectedItem, setSelectedItem] = useState("");
    const [selectedType, setSelectedType] = useState(0);
    const [disableAmount, setDisableAmount] = useState(false);
    const [amount, setAmount] = useState<number>();
    const [quantity, setQuantity] = useState(1);
    const [description, setDescription] = useState("");
    const [pickDate, setPickDate] = useState(true);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [dateTimeMode, setDateTimeMode] = useState(true);
    const [errorMessages, setErrorMessage] = useState("");

    const { acquisitionProcessing, listingItems, acquisition, acquisitionErrorMessage } = useSelector(
        (state: RootState) => state.trade
    )

    useEffect(() => {
        if (!acquisitionProcessing)
            dispatch(fetchAllListingItems(companyPin));
    }, []);

    const onSelectedItemHandle = (item: IListing | null) => {
        if (!item) {
            setDisableAmount(false);
            setAmount(0);
            return;
        }
        else if(item?.price > 0){
            setDisableAmount(true);
        }
        setAmount(item?.price);
        setDescription(item?.description); //`${item?.alias} - ${description}`);
        setSelectedItem(item?.id);
    }

    const onContinueClick = (e: any) => {
        e.preventDefault();
        dispatch(clearErrorMessage());
        /*  if(selectedType === "0") {
             setMessage("Kindly select the Type of your request");          
             return;
         } */
        if (!amount) {
            setErrorMessage("Please specify the Amount to pay")
            return;
        }
        else if (!description) {
            setErrorMessage("Please describe to the vendor what is the payment about");
            return;
        }
        else if (!quantity || quantity < 1) {
            setErrorMessage("Invalid quantity");
            return;
        }
        else if ((amount * quantity) > 3000) {
            setErrorMessage("Sorry, your payment amount multiply the quantity is over the KhonaBiz limit of R 3 000");
            return;
        }

        var model = new AcquisitionModel(selectedItem, 7, description.trim(), amount, quantity, selectedDate, emailAddress);

        if (selectedItem) {
            dispatch(submitAcquisistion(model));
        }
        else {
            dispatch(setAcquisition(model));
        }
    }

    if (!acquisitionProcessing && !acquisitionErrorMessage && acquisition)
        history.replace(appRoutes.pay);

    return (
        <div>
            <p className="Sub-header" style={{ color: Colors.ivory }}>{heading ? heading : "Buy"}</p>
            <form onSubmit={onContinueClick} className={classes.container}>
                <div className={`Form-input-container${isMobilePhone ? "-mobile" : ""}`}>
                    <FormControl variant="outlined">
                        {listingItems ? <Autocomplete
                            id="combo-box-demo"
                            options={listingItems}
                            getOptionLabel={(option) => option.alias}
                            className={`Input${isMobilePhone ? "-mobile" : ""}`}
                            onChange={(e, value) => onSelectedItemHandle(value)}
                            renderInput={(params) =>
                                <TextField {...params}
                                    label=""
                                    className={`Input${isMobilePhone ? "-mobile" : ""}`}
                                    style={isMobilePhone ? { marginRight: 0 } : { marginRight: 20 }}
                                    value={selectedItem}
                                    variant="outlined" />}
                        /> : <span style={{ color: Colors.ivory1 }}>loading items...</span>}
                        <FormHelperText style={{ color: 'lightgray' }}>Select what to pay for?</FormHelperText>
                    </FormControl>
                    <TextField
                        disabled={disableAmount}
                        label='Amount (offer)'
                        variant="outlined"
                        color="primary"
                        className={`Input${isMobilePhone ? "-mobile" : ""}`}
                        InputProps={{
                            inputComponent: AmountInputFormat as any,
                        }}
                        onChange={(e) => setAmount(parseFloat(e.target.value.trim()))}
                        value={amount}
                    />
                    <TextField
                        label='Quantity'
                        variant="outlined"
                        color="primary"
                        type="number"
                        className={`Input${isMobilePhone ? "-mobile" : ""}`}
                        style={{ width: isMobilePhone ? 222 : 70 }}
                        onChange={(e) => setQuantity(parseInt(e.target.value.trim()))}
                        value={quantity}
                    />
                </div>
                <div className={`Form-input-container${isMobilePhone ? "-mobile" : ""}`} style={{ paddingLeft: 5 }}>
                    <TextField
                        multiline={true}
                        rows={2}
                        label='Description / Comment'
                        variant="outlined"
                        color="primary"
                        className={`Input${isMobilePhone ? "-mobile" : ""}`}
                        style={{ width: isMobilePhone ? '90%' : '100%', maxWidth: 530, marginRight: 24 }}
                        onChange={(e) => setDescription(e.target.value)}
                        value={description}
                    />
                    {/*  <TouchableOpacity
                        className={[classes.inputContainer, {padding: 10, margin: 10}]}
                        onPress={() => this.setState({ pickDate: true })}>
                        <Text className={{fontSize: 18, width: '95%'}}
                            >{selectedDateTime}</Text>
                        <Ionicons
                            name="md-calendar"
                            color={Colors.grey1}
                            size={22}
                            className={{marginRight: 5}}
                        /> 
                    </TouchableOpacity>
                    <Text className={MainStyles.caption}>Pick Date</Text>
                    {this.state.pickDate && (
                        <DateTimePicker
                            testID="dateTimePicker"
                            value={this.state.selectedDate || new Date()}
                            mode={this.state.dateTimeMode}
                            is24Hour={true}
                            display="default"
                            timeZoneOffsetInSeconds={7200}
                            onChange={(event, selected) => this.onDateChange(event, selected)}
                        />)
                    } */}
                </div>
                <Button
                    disabled={acquisitionProcessing}
                    variant="outlined"
                    className={`Button${isMobilePhone ? "-mobile" : ""}`}
                    type="submit">
                    Continue
                </Button>
            </form>
            {acquisitionProcessing ? <CircularProgress /> : null}
            <p style={{ color: Colors.lightRed }}>{errorMessages}</p>
            <HttpResponseMessages />
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        textAlign: 'center',
        borderRadius: 2,
        marginBottom: 0,
    },
}));