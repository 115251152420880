import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'app/rootReducer';
import appRoutes from 'app/appRoutes';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import { TextField, Button, CircularProgress, ButtonBase } from '@material-ui/core';

import logo from '../../img/logo4.png';
import Colors from 'Colors';

import UserModel from 'models/User';
import UserProfileModel from 'models/UserProfile';
import { registerUser, submitCompanyProfile, submitDetailedProfile, submitUserProfile, uploadCompanyBanner } from './accountApi';
import { openPopUpMessage } from 'app/appSlice'
import { setOnBoarding } from './userSlice';
import HttpResponseMessages from 'features/errorHandling/HttpResponseMessages';
import { clearErrorMessage } from 'features/errorHandling/errorHandlingSlice';
import CompanyProfileModel from 'models/CompanyProfileModel';
import DetailedProfileModel from 'models/DetailedProfileModel';

interface Props {
    isMobilePhone: boolean
}
export default function CreateLead({ isMobilePhone }: Props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    let history = useHistory();

    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [email, setEmail] = useState("");
    const [password] = useState(() => {
        const charsetSmallLetter = "abcdefghijklmnopqrstuvwxyz",
        charsetBigLetter = "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
        charsetNumbers = "0123456789",
        charsetSpecialCharacters = "!#$*_-.+";
        let results = "";
        for (let i = 0, n = charsetSmallLetter.length; i < 3; ++i) {
            results += charsetSmallLetter.charAt(Math.floor(Math.random() * n));
        }
        for (let i = 0, n = charsetNumbers.length; i < 3; ++i) {
            results += charsetNumbers.charAt(Math.floor(Math.random() * n));
        }
        for (let i = 0, n = charsetSpecialCharacters.length; i < 1; ++i) {
            results += charsetSpecialCharacters.charAt(Math.floor(Math.random() * n));
        }
        for (let i = 0, n = charsetBigLetter.length; i < 2; ++i) {
            results += charsetBigLetter.charAt(Math.floor(Math.random() * n));
        }
        return results;
    });
    const [contacts, setContacts] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    
    const showPopupMessage = useSelector(
        (state: RootState) => state.app.showPopupMessage
    )

    const { userProcessing, isOnBoarding, userProcessingError, userRegisteredSuccessfully, userProfileCreatedSuccessfully, accessToken } = useSelector(
        (state: RootState) => state.user
    )

    const { companyProfileId, companyProfileProcessing, detailedProfileSubmittedSuccessfully, companyBannerUploadProcessing, companyBannerUploadedSuccessfully, companyProfileProcessingError } = useSelector(
        (state: RootState) => state.companyProfile
    )

    useEffect(() => {
        if (!isOnBoarding) dispatch(setOnBoarding(true));
    }, [])

    const onSubmitClick = (e: any) => {
        e.preventDefault();
        dispatch(clearErrorMessage());
        setErrorMessage("");
        //Validate
        if (!name) {
            setErrorMessage("Name not specified.");
            return;
        }
        if (!surname) {
            setErrorMessage("Surname not specified.");
            return;
        }
        else if (!email) {
            setErrorMessage("Email not specify.");
            return;
        }
        else if (!password) {
            setErrorMessage("Password not entered.");
            return;
        }
        else if (password == 'Pass123$') {
            setErrorMessage("You cannot use sample password 'Pass123$'")
            return;
        }

        if (email) {
            var mailformat = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            ///^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;            
            if (!mailformat.test(email)) {
                setErrorMessage("Invalid Email.");
                return;
            }
        }

        if (contacts) {
            if (contacts.length < 9 || contacts.length > 20) {
                setErrorMessage("Invalid Contacts.");
                return;
            }
        }

        const model = new UserModel(email, password);

        dispatch(registerUser(model));
    }

    if (userRegisteredSuccessfully && accessToken && !userProfileCreatedSuccessfully && !userProcessing) {
        const model = new UserProfileModel(name.trim(), surname.trim(), email, contacts);

        dispatch(submitUserProfile(accessToken, model));
    }

    if (!companyProfileId && !userProcessing && !companyProfileProcessing && userRegisteredSuccessfully && userProfileCreatedSuccessfully && !showPopupMessage && !companyProfileProcessingError) {
        dispatch(openPopUpMessage("Your Account has been created!"));    
        const model = new CompanyProfileModel(name.trim(), email, contacts);
        dispatch(submitCompanyProfile(accessToken, null, model));
    }

    let friendlyUserName = "";
    if(companyProfileId && !companyProfileProcessingError){
        friendlyUserName = `@${name.replace(/\s+/g, '')}`;
        const model = new DetailedProfileModel(companyProfileId, friendlyUserName, "", friendlyUserName, "30, 30, 30");

        if(!companyProfileProcessing && !detailedProfileSubmittedSuccessfully) 
            dispatch(submitDetailedProfile(accessToken, model));
    }
    
    if(companyProfileId && detailedProfileSubmittedSuccessfully && !companyBannerUploadProcessing && !companyBannerUploadedSuccessfully && !companyProfileProcessingError) {       
        dispatch(uploadCompanyBanner(accessToken, null, companyProfileId));
    }
    
    const ProcessingMessage = () => {
        return(
            <>
                <div>
                    <h4>Information submitted successfully!</h4>
                </div>
                <div style={{ marginBottom: 10 }}>
                    <span>Building your default profile webpage</span>
                </div>
                <div>{(userProcessing || companyProfileProcessing || companyBannerUploadProcessing) && !companyProfileProcessingError ? 
                    <CircularProgress size={30} color="secondary" /> : 
                    <><div><h5>Completed!</h5></div><div><a href={`https://web.khonabiz.com/?${friendlyUserName}`} target="_blank">Preview your profile</a></div></>}</div>
            </>
        )
    }
    return (
        <div style={{ justifyItems: 'center' }}>
            <form onSubmit={onSubmitClick} className={classes.container}>
                <p className="Sub-header">Getting Started</p>
                {!accessToken? <>
                    <TextField
                        label='Your Name'
                        variant="outlined"
                        margin="dense"
                        className={classes.input}
                        onChange={(e) => setName(e.target.value)}
                        value={name}
                    />
                    <TextField
                        label='Surname'
                        variant="outlined"
                        margin="dense"
                        className={classes.input}
                        onChange={(e) => setSurname(e.target.value)}
                        value={surname}
                    />
                    <TextField
                        label='Email'
                        variant="outlined"
                        margin="dense"
                        className={classes.input}
                        onChange={(e) => setEmail(e.target.value.trim())}
                        value={email}
                    />
                    <TextField
                        label='Contact Numbers'
                        variant="outlined"
                        margin="dense"
                        className={classes.input}
                        onChange={(e) => setContacts(e.target.value.trim())}
                        value={contacts}
                    />
                    <Button
                        disabled={userProcessing}
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        type="submit">
                        Submit
                    </Button>
                    {userProcessing ? <CircularProgress color="secondary" /> : null}                
                </> : <ProcessingMessage />}
                <p className={classes.errorMessage}>{errorMessage}</p>
                <HttpResponseMessages showInPopup={true} />
                <ButtonBase onClick={() => window.location.replace("https://khonabiz.co.za")}>
                    <img src={logo} style={{ width: 170, marginTop: 12 }} alt="KhonaBiz" />
                </ButtonBase>
            </form>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'inline-flex',
        flexDirection: 'column',
        maxWidth: 320,
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(2),
        backgroundColor: Colors.ivory,
        alignItems: 'center',
        borderRadius: 2,
        marginBottom: 0,
    },
    input: {
        width: 222,
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(1 * 0.5),
        marginRight: theme.spacing(1 * 0.5)
    },
    button: {
        width: 150,
        margin: theme.spacing(2),
    },
    errorMessage: {
        color: Colors.maroon,
        fontSize: 15,
        margin: 0
    }
}));