import { AppThunk } from 'app/store';
import IAcquisition from 'interfaces/IAcquisition';
import ICatalogueItem from 'interfaces/ICatalogueItem';
import IEnquiry from 'interfaces/IEnquiry';
import IListing from 'interfaces/IListing';

import { setErrorCode, setErrorMessage } from 'features/errorHandling/errorHandlingSlice';
import {
    tradeProcessingStart, setTradeError,
    setCatalogueItems, setItemCategories, setCatalogueItem,
    setListingSavedSuccessfully, setListingPictureUploaded, setListingItems, setListingItem, loadListingItemStart,
    processAcquisitionStart, setAcquisition, processAcquisitionError,
    processEnquiryStart, processEnquirySuccess, processEnquiryError, setBusinessTypes
} from './tradeSlice';

export const submitAcquisistion = (data: IAcquisition, toPay: boolean = true): AppThunk => async dispatch => {
    try {
        dispatch(processAcquisitionStart());
        const url = `${process.env.REACT_APP_TRADE_URL}/api/Acquisition`;
        await fetch(url,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            }
        ).then(response => {
            if (response.status === 200) {
                response.json().then(results => {
                    if(toPay) { 
                        dispatch(setAcquisition(data));
                    }
                    else{
                        dispatch(processEnquirySuccess());
                    }
                })
            }
            else {
                dispatch(processAcquisitionError("Could not process your acquisition, please try again"));
                dispatch(setErrorCode(response.status));
            }
        })
            .catch((error) => {
                dispatch(processAcquisitionError(error.message));
                dispatch(setErrorMessage(error.message));
            })
    }
    catch (error) {
        dispatch(processAcquisitionError(error.message));
        dispatch(setErrorMessage(error.message));
    }
}

export const submitEnquiry = (data: IEnquiry): AppThunk => async dispatch => {
    try {
        dispatch(processEnquiryStart());
        const url = `${process.env.REACT_APP_ACCOUNT_URL}/api/PublicProfile/Enquire`;
        await fetch(url,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            }
        ).then(response => {
            if (response.status === 200) {
                dispatch(processEnquirySuccess());
            }
            else {
                dispatch(processEnquiryError("Could not submit enquiry, please try again"));
                dispatch(setErrorCode(response.status));
            }
        })
            .catch(error => {
                dispatch(processEnquiryError("Could not submit enquiry, please try again"));
                dispatch(setErrorMessage(error.message));
            })
    }
    catch (error) {
        dispatch(processEnquiryError(error.message));
        dispatch(setErrorMessage(error.message));
    }
}

export const fetchAllListingItems = (companyPin: string): AppThunk => async dispatch => {
    try {
        dispatch(tradeProcessingStart());
        const url = `${process.env.REACT_APP_TRADE_URL}/api/Listing?companyPin=${companyPin}`;
        await fetch(url,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            }).then(response => {
                if (response.status === 200) {
                    response.json().then(results => {
                        dispatch(setListingItems(results));
                    })
                }
                else {
                    dispatch(setTradeError("Could not load Items"));
                    dispatch(setErrorCode(response.status));
                }
            })
            .catch(error => {
                dispatch(setTradeError("Could not load Items"));
                dispatch(setErrorMessage(error.message));
            });
    }
    catch (error) {
        dispatch(setTradeError(error.message));
        dispatch(setErrorMessage(error.message));
    }
}

export const fetchProducts = (companyPin: string): AppThunk => async dispatch => {
    try {
        dispatch(tradeProcessingStart());
        const url = `${process.env.REACT_APP_TRADE_URL}/api/Listing/Products?companyPin=${companyPin}`;
        await fetch(url,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            }).then(response => {
                if (response.status === 200) {
                    response.json().then(results => {
                        dispatch(setListingItems(results));
                    })
                }
                else {
                    dispatch(setTradeError("Could not load Items"));
                    dispatch(setErrorCode(response.status));
                }
            })
            .catch(error => {
                dispatch(setTradeError("Could not load Items"));
                dispatch(setErrorMessage(error.message));
            });
    }
    catch (error) {
        dispatch(setTradeError(error.message));
        dispatch(setErrorMessage(error.message));
    }
}

export const fetchProductById = (id: string): AppThunk => async dispatch =>  {
    try {
        dispatch(loadListingItemStart());
        const url = `${process.env.REACT_APP_TRADE_URL}/api/Listing/Item?id=${id}`;
        await fetch(url,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            }).then(response => {
                if (response.status === 200) {
                    response.json().then(results => {
                        dispatch(setListingItem(results));
                    })
                }
                else {
                    dispatch(setTradeError("Could not load Items"));
                    dispatch(setErrorCode(response.status));
                }
            })
            .catch(error => {
                dispatch(setTradeError("Could not load Items"));
                dispatch(setErrorMessage(error.message));
            });
    }
    catch (error) {
        dispatch(setTradeError(error.message));
        dispatch(setErrorMessage(error.message));
    }
}

export const fetchAllCatalogueItems = (token: any): AppThunk => async dispatch => {
    try {
        dispatch(tradeProcessingStart());
        const url = `${process.env.REACT_APP_TRADE_URL}/api/Item`;
        await fetch(url,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'bearer ' + token
                },
            }).then(response => {
                if (response.status === 200) {
                    response.json().then(results => {
                        dispatch(setCatalogueItems(results));
                    })
                }
                else {
                    dispatch(setTradeError("Could not load Items"));
                    dispatch(setErrorCode(response.status));
                }
            })
            .catch(error => {
                dispatch(setTradeError("Could not load Items"));
                dispatch(setErrorMessage(error.message));
            });
    }
    catch (error) {
        dispatch(setTradeError(error.message));
        dispatch(setErrorMessage(error.message));
    }
}

export const fetchAllItemCategories = (token: any): AppThunk => async dispatch => {
    try {
        dispatch(tradeProcessingStart());
        const url = `${process.env.REACT_APP_TRADE_URL}/api/Item/Categories`;
        await fetch(url,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'bearer ' + token
                },
            }).then(response => {
                if (response.status === 200) {
                    response.json().then(results => {
                        dispatch(setItemCategories(results));
                    })
                }
                else {
                    dispatch(setTradeError("Could not load Categories"));
                    dispatch(setErrorMessage("Could not load Categories"));
                }
            })
            .catch(error => {
                dispatch(setTradeError("Could not load Categories"));
                dispatch(setErrorMessage(error.message));
            });
    }
    catch (error) {
        dispatch(setTradeError(error.message));
        dispatch(setErrorMessage(error.message));
    }
}

export const fetchAllBusinessTypes = (token: any): AppThunk => async dispatch => {
    try {
        dispatch(tradeProcessingStart());
        const url = `${process.env.REACT_APP_TRADE_URL}/api/Item/BusinessTypes`;
        await fetch(url,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'bearer ' + token
                },
            }).then(response => {
                if (response.status === 200) {
                    response.json().then(results => {
                        dispatch(setBusinessTypes(results));
                    })
                }
                else {
                    dispatch(setTradeError("Could not load Business Types / Industries"));
                    dispatch(setErrorCode(response.status));
                }
            })
            .catch(error => {
                dispatch(setTradeError("Could not load Business Types / Industries"));
                dispatch(setErrorMessage(error.message));
            });
    }
    catch (error) {
        dispatch(setTradeError(error.message));
        dispatch(setErrorMessage(error.message));
    }
}

export const submitListing = (token: string, data: IListing): AppThunk => async dispatch => {
    try {
        dispatch(tradeProcessingStart());
        const url = `${process.env.REACT_APP_TRADE_URL}/api/Listing`;
        await fetch(url,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'bearer ' + token
                },
                body: JSON.stringify(data),
            }
        ).then(response => {
            if (response.status === 200) {
                response.json().then(results => {
                    dispatch(setListingSavedSuccessfully(results));
                })
            }
            else {
                dispatch(setTradeError("Could not save Item, please try again"));
                dispatch(setErrorMessage("Could not save Item, please try again"));
            }
        })
            .catch(error => {
                dispatch(setTradeError("Could not save Item, please try again"));
                dispatch(setErrorMessage("Could not save Item, please try again"));
            })
    }
    catch (error) {
        dispatch(setTradeError(error.message));
        dispatch(setErrorMessage(error.message));
    }
}

export const uploadListingPicture = (token: string, listingId: string, file: any): AppThunk => async dispatch => {
    const body = new FormData();
    body.append('displayPicture', file);

    try {
        dispatch(tradeProcessingStart());
        const url = `${process.env.REACT_APP_TRADE_URL}/api/Listing/DisplayPicture?listingId=${listingId}`;
        await fetch(url,
            {
                method: 'PUT',
                headers: {
                    //'Content-Type': 'multipart/form-data',
                    'Authorization': 'bearer ' + token
                },
                body
            }
        ).then(response => {
            if (response.status === 200) {
                dispatch(setListingPictureUploaded());
            }
            else {
                dispatch(setTradeError("Could not upload picture, please try again"));
                dispatch(setErrorMessage("Could not upload picture, please try again"));
            }
        })
            .catch(error => {
                dispatch(setTradeError("Could not upload picture, please try again"));
                dispatch(setErrorMessage("Could not upload picture, please try again"));
            })
    }
    catch (error) {
        dispatch(setTradeError(error.message));
        dispatch(setErrorMessage("Could not upload picture, please try again"));
    }
}

export const submitNewItem = (token: string, data: ICatalogueItem): AppThunk => async dispatch => {
    try {
        dispatch(tradeProcessingStart());
        const url = `${process.env.REACT_APP_TRADE_URL}/api/Item`;
        await fetch(url,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'bearer ' + token
                },
                body: JSON.stringify(data),
            }
        ).then(response => {
            if (response.status === 200) {
                response.json().then(results => {
                    dispatch(setCatalogueItem(results));
                })
            }
            else {
                dispatch(setTradeError("Could not save Item, please try again"));
                dispatch(setErrorMessage("Could not save Item, please try again"));
            }
        })
            .catch(error => {
                dispatch(setTradeError("Could not save Item, please try again"));
                dispatch(setErrorMessage("Could not save Item, please try again"));
            })
    }
    catch (error) {
        dispatch(setTradeError(error.message));
        dispatch(setErrorMessage("Could not save Item, please try again"));
    }
}
