import IUser from 'interfaces/IUser';

export default class UserModel implements IUser {
    email: string;
    password: string;
    //isActive: boolean;

    constructor(emailAddress: string, passsword: string){
        this.email = emailAddress;
        this.password = passsword;
        //this.isActive = true;
    }
}