import ICompanyProfile from 'interfaces/ICompanyProfile';

export default class CompanyProfileModel implements ICompanyProfile {
    id: string;
    pin: string;
    name: string;
    tradingName: string;
    registrationNumber: string;
    email: string;
    contacts: string;
    alternativeContacts: string;
    website: string;
    primaryService: string;
    companyType: number;
    logo: any;
    
    constructor(name: string, email: string, contacts: string){
        this.id = "";
        this.pin = "0";
        this.name = name;
        this.tradingName = name;
        this.registrationNumber = '';
        this.email = email;
        this.contacts = contacts;
        this.alternativeContacts = '';
        this.website = '';
        this.primaryService = '';
        this.companyType = 4;
        this.logo = null;
    }    
}