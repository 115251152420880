import { combineReducers } from "redux";

import appReducer from './appSlice';
import errorHandlingReducer from 'features/errorHandling/errorHandlingSlice';
import profileReducer from 'features/profile/profileSlice';
import tradeReducer from 'features/trade/tradeSlice';
import userReducer from "features/account/userSlice";
import companyProfileReducer from 'features/account/companyProfileSlice';
import paymentReducer from 'features/payment/paymentSlice';
import searchReducer from 'features/search/searchSlice';

const rootReducer = combineReducers({
    app: appReducer,
    error: errorHandlingReducer,
    profile: profileReducer,
    trade: tradeReducer,
    user: userReducer,
    companyProfile: companyProfileReducer,
    payment: paymentReducer,
    search: searchReducer
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer