import SignIn from 'features/account/SignIn';
import SignOut from 'features/account/SignOut';
import Search from 'features/search/Search';
import Profile from 'features/profile/Profile';
import CreateLead from 'features/account/CreateLead';
import RegisterUser from 'features/account/RegisterUser';
import CreateCompany from 'features/account/CreateCompany';
import DetailedProfile from 'features/account/DetailedProfile';
import SubmitPayment from 'features/payment/SubmitPayment';
import Acquisition from 'features/trade/Acquisition';
import PaymentUserInfo from 'features/payment/PaymentUserInfo';
import ItemListing from 'features/trade/ItemListing';
import Products from 'features/trade/Products';
import ProductDetails from 'features/trade/ProductDetails';

const appRoutes = {
  home: '/',
  search: '/search',
  profile: '/profile',
  signIn: '/signin',
  signOut: '/signout',
  createLead: '/lead',
  signUp: '/register',
  createCompany: '/create-company',
  detailedProfile: '/detailed-profile',
  acquire: '/acquisition',
  paymentUserInfo: '/pay-details',
  pay: '/pay',
  listItems: '/list-Items',
  products: '/products',
  productDetails: '/products-details'
}

export default appRoutes

export const routes = [
  {
    path: appRoutes.search,
    component: Search
  },
  {
    path: appRoutes.signIn,
    component: SignIn,
  },
  {
    path: appRoutes.signOut,
    component: SignOut
  },
  {
    path: appRoutes.createLead,
    component: CreateLead
  },
  {
    path: appRoutes.signUp,
    component: RegisterUser
  },
  {
    path: appRoutes.createCompany,
    component: CreateCompany
  },
  {
    path: appRoutes.detailedProfile,
    component: DetailedProfile
  },
  {
    path: appRoutes.listItems,
    component: ItemListing
  },
  {
    path: appRoutes.products,
    component: Products
  },
  /* {
    path: `${appRoutes.productDetails}/:id`,
    component: ProductDetails
  }, */
  {
    path: appRoutes.pay,
    component: SubmitPayment
  },
  {
    path: appRoutes.paymentUserInfo,
    component: PaymentUserInfo
  },
  {
    path: appRoutes.signOut,
    component: SignOut
  },
]