import React, { useEffect, useState } from 'react';

import { Grid, Card, CardContent, ButtonBase, makeStyles, Button } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { RootState } from 'app/rootReducer';
import appRoutes from 'app/appRoutes';

import Colors from 'Colors';

import { fetchAllCatalogueItems } from './tradeApi';
import { setCatalogueItem } from './tradeSlice';
import ICatalogueItem from 'interfaces/ICatalogueItem';
import NewCatalogueItem from './NewCatalogueItem';
import HttpResponseMessages from 'features/errorHandling/HttpResponseMessages';

export default function CatalogueItems() {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();

    const { accessToken, userBusinessProfile } = useSelector(
        (state: RootState) => state.user
    )

    const { catalogueItems, loadTradeProcessing, tradeErrorMessage } = useSelector(
        (state: RootState) => state.trade
    )

    var [specifyNewItem, setSpecifyNewItem] = useState(false);

    useEffect(() => {
        if (accessToken && !catalogueItems && !loadTradeProcessing && !tradeErrorMessage)
        {
            dispatch(fetchAllCatalogueItems(accessToken));
        }
    }, [accessToken, catalogueItems, loadTradeProcessing, tradeErrorMessage])

    interface IItemCardProps {
        item: ICatalogueItem
    }
    const ItemCard = ({ item }: IItemCardProps) => {
        return (
            <ButtonBase onClick={() => dispatch(setCatalogueItem(item))}>
                <Card variant="outlined" className={classes.cardContainer}>
                    <CardContent>
                        <div style={{ fontSize: 18 }}>{item.description}</div>
                        <div style={{ color: Colors.grey3 }}>{item.category}</div>
                    </CardContent>
                </Card>
            </ButtonBase>
        )
    }

    return (
        <Grid container className={classes.container}>
            <Grid item xs={12}>
                <HttpResponseMessages />
            </Grid>
            <Grid item xs={12}>
                {specifyNewItem ? <NewCatalogueItem onCloseNewItem={setSpecifyNewItem} /> : null}
            </Grid>
            <Grid item xs={12}>
                {userBusinessProfile && <Button
                    disabled={loadTradeProcessing}
                    variant="outlined"
                    href={`/?${userBusinessProfile?.businessUserName}&source=test`}>
                    View your Buiness Profile
                </Button>}
            </Grid>
            <Grid item xs={12}>
                <h2 style={{ color: Colors.ivory2 }}>Select Item your business provide</h2>
            </Grid>
            <Grid item xs={12}>
                <Grid container justify="center" spacing={2}>
                    <Grid>
                        <ButtonBase onClick={() => setSpecifyNewItem(true)}>
                            <Card variant="outlined" className={classes.newItemCardContainer}>
                                <CardContent>
                                    <div style={{ fontSize: 28 }}>+</div>
                                    <div style={{ color: Colors.grey1 }}>Other (specify)</div>
                                </CardContent>
                            </Card>
                        </ButtonBase>
                    </Grid>
                    {catalogueItems ? catalogueItems?.map((item, index) => (
                        <Grid key={index}>
                            <ItemCard item={item}></ItemCard>
                        </Grid>
                    )) : null}
                </Grid>
            </Grid>
        </Grid>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(2)
    },
    newItemCardContainer: {
        display: 'inline-flex',
        width: 200,
        height: 100,
        alignItems: 'center',
        justifyContent: 'center',
        borderColor: Colors.ivory,
        margin: theme.spacing(1),
        backgroundColor: Colors.grey2,
        color: Colors.ivory2,
    },
    cardContainer: {
        display: 'inline-flex',
        width: 200,
        height: 100,
        alignItems: 'center',
        justifyContent: 'center',
        borderColor: Colors.ivory,
        margin: theme.spacing(1),
        backgroundColor: Colors.transparent,
        color: Colors.ivory2
    },
}));