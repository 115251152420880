import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import IDetailedProfile from 'interfaces/IDetailedProfile';
import IProfileBanner from 'interfaces/IProfileBanner';
import IProfileLink from 'interfaces/IProfileLink';

interface IProfileState {
    profileIsLoading: boolean,
    isLoadingOtherInfo: boolean,
    detailedProfile: IDetailedProfile | null,
    profileBanner: Array<IProfileBanner> | null,
    isLoadingCatalogueCategories: boolean,
    catalogueCategories: Array<string> | null,
    profileLinks: Array<IProfileLink> | null,
    visitId: string,
    profileErrorMessage: string
}

let initialState : IProfileState = {
    profileIsLoading: false,
    isLoadingOtherInfo: false,
    detailedProfile: null,
    profileBanner: null,
    isLoadingCatalogueCategories: false,
    catalogueCategories: null,
    profileLinks: null,
    visitId: "",
    profileErrorMessage: ""
}

const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        loadProfileStart(state) {
            state.profileIsLoading = true;
            state.profileErrorMessage = "";
        },
        clearProfile(state){
            state.detailedProfile = null;
            state.profileBanner = null;
            state.profileLinks = null;
            state.catalogueCategories = null;
            state.visitId = "";
            state.profileErrorMessage = "";
        },
        loadOtherInfoStart(state) {
            state.isLoadingOtherInfo = true;
            state.profileErrorMessage = "";
        },
        profileFound(state, { payload }: PayloadAction<IDetailedProfile>){
            state.profileIsLoading = false;
            state.detailedProfile = payload;
            state.visitId = payload.visitId;
        },
        profileBannerFound(state, { payload }: PayloadAction<Array<IProfileBanner>>){
            state.profileIsLoading = false;
            //state.isLoadingOtherInfo = false;
            state.profileBanner = payload;
        },
        loadCatalogueCategoriesStart(state) {
            state.isLoadingCatalogueCategories = true;
            state.profileErrorMessage = "";
        },
        catalogueCategoriesFound(state, { payload }: PayloadAction<Array<string>>){
            state.isLoadingCatalogueCategories = false;
            state.catalogueCategories = payload;
        },
        loadCatalogueCategoriesError(state, { payload }: PayloadAction<string>) {
            state.isLoadingCatalogueCategories = true;
            state.profileErrorMessage = payload;
        },
        profileLinksFound(state, { payload }: PayloadAction<Array<IProfileLink>>){
            state.profileIsLoading = false;
            //state.isLoadingOtherInfo = false;
            state.profileLinks = payload;
        },
        setVisitId(state, { payload }: PayloadAction<string>){
            state.visitId = payload;
            //state.isLoadingOtherInfo = false;
        },
        setLoadOtherInfoComplete(state){
            state.isLoadingOtherInfo = false;
        },
        loadProfileError(state, { payload }: PayloadAction<string>) {
            state.profileIsLoading = false;
            state.isLoadingOtherInfo = false;
            state.detailedProfile = null;
            state.profileBanner = null;
            state.catalogueCategories = null;
            state.profileErrorMessage = payload;
        }
    }
});

export const {
    loadProfileStart,
    loadOtherInfoStart,
    clearProfile,
    profileFound,
    profileBannerFound,
    loadCatalogueCategoriesStart,
    catalogueCategoriesFound,
    loadCatalogueCategoriesError,
    profileLinksFound,
    setVisitId,
    setLoadOtherInfoComplete,
    loadProfileError,
} = profileSlice.actions;

export default profileSlice.reducer;