import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "app/rootReducer";
import { ButtonBase, makeStyles } from "@material-ui/core";
import { useHistory } from "react-router";
import appRoutes from "app/appRoutes";

export default function ProductsHeader() {
    const classes = useStyles();
    const history = useHistory();
    
    const detailedProfile = useSelector(
        (state: RootState) => state.profile.detailedProfile
    )
    
    const displayPicture = detailedProfile?.profile.logo ? `data:image/png;base64,${detailedProfile?.profile.logo}` : null;
    
    if(!displayPicture) return null;

    return(
        <header className={classes.header}>
            <ButtonBase onClick={() => history.push(appRoutes.home)}>
                <img src={displayPicture} className={classes.displayPicture} alt={detailedProfile?.profile.tradingName} />
            </ButtonBase>
            <div className={classes.displayName}>{detailedProfile?.profile.tradingName}</div>
        </header>
    )
}

const useStyles = makeStyles((theme) => ({    
    header: {
        fontFamily: "'Spartan', sans-serif",
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        fontSize: 10,
        color: '#fffff0',
        margin: 20,
        zIndex: 1000
    },
    displayName: {
        width: "85%", 
        borderBottom: "1.5px inset #fffff0", 
        padding: 10, 
        margin: 10, 
        marginRight: 20, 
        fontSize: 17, 
        textAlign: "left"
    },
    displayPicture: {
        height: "14vmin", 
        width: "14vmin", 
        borderRadius: "50%", 
        objectFit: "contain", 
        backgroundColor: "black", 
        margin: 10,
        marginTop: 0,
        marginBottom: 0
    },
}));