import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'app/rootReducer';
import { Redirect } from 'react-router-dom';
import appRoutes from 'app/appRoutes';

import { makeStyles } from '@material-ui/core/styles';
import { TextField, Button, CircularProgress, FormHelperText, Grid, Box } from '@material-ui/core';

import Colors from 'Colors';

import { AmountDisplayFormat } from 'utils/NumberFormatUtils';

import AcquisitionModel from 'models/AcquisitionModel';
import { fetchAllListingItems, submitAcquisistion } from './tradeApi';
import { setAcquisition } from './tradeSlice';
import HttpResponseMessages from 'features/errorHandling/HttpResponseMessages';
import { clearErrorMessage } from 'features/errorHandling/errorHandlingSlice';

interface Props {
    item: string,
    heading: string,
    price: number,
    companyPin: string,
    emailAddress: string,
    isMobilePhone: boolean
}
export default function SHowItem({ item, heading, price, companyPin, emailAddress, isMobilePhone }: Props) {
    const dispatch = useDispatch();
    const classes = useStyles();

    const [amount, setAmount] = useState<number>(price);
    const [quantity, setQuantity] = useState(1);
    const [description, setDescription] = useState("");
    const [errorMessages, setErrorMessage] = useState("");

    const { acquisitionProcessing, acquisition, enquirySubmittedSuccessfully, acquisitionErrorMessage } = useSelector(
        (state: RootState) => state.trade
    )

    const onContinueClick = (e: any, toPay: boolean = true) => {
        e.preventDefault();
        dispatch(clearErrorMessage());
        /*  if(selectedType === "0") {
             setMessage("Kindly select the Type of your request");          
             return;
         } */
        /* if (!amount) {
            setErrorMessage("Please specify the Amount to pay")
            return;
        }
        else if (!description) {
            setErrorMessage("Please describe to the vendor what is the payment about");
            return;
        } */
        if (!quantity || quantity < 1) {
            setErrorMessage("Invalid quantity");
            return;
        }
        else if ((amount * quantity) > 3000) {
            setErrorMessage("Sorry, your payment amount multiply the quantity is over the KhonaBiz limit of R 3 000");
            return;
        }

        var model = new AcquisitionModel(item, 1, description.trim(), amount, quantity, new Date(), emailAddress);

        if (item) {
            dispatch(submitAcquisistion(model, toPay));
        }
        else {
            dispatch(setAcquisition(model));
        }
    }

    if (!acquisitionProcessing && !acquisitionErrorMessage && acquisition)
        return <Redirect to={appRoutes.pay} />; //history.push(appRoutes.pay); 

    if (!acquisitionProcessing && !acquisitionErrorMessage && enquirySubmittedSuccessfully){
        return(
            <Box style={{ color: Colors.ivory }}>
                <h2>Submitted Successfully!</h2>
                <Button
                    disabled={acquisitionProcessing}
                    variant="contained"
                    className={`Button${isMobilePhone ? "-mobile" : ""}`}
                    color="primary"
                    onClick={() => window.location.replace(`${window.location.protocol == "https" ? "https" : "http"}://${window.location.host}`)}>
                    Back to Home
                </Button>
            </Box>
        )
    }

    return (
        <div className={classes.container}>
            <p className="Sub-header" style={{ color: Colors.ivory, fontSize: 18 }}>{heading}</p>
            <form onSubmit={onContinueClick}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={7} style={{ color: Colors.ivory, fontSize: 17 }}>                    
                        <AmountDisplayFormat displayCurrency>
                            {amount}
                        </AmountDisplayFormat>
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <TextField
                            label='Quantity'
                            variant="outlined"
                            color="primary"
                            type="number"
                            onChange={(e) => setQuantity(parseInt(e.target.value.trim()))}
                            value={quantity}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            multiline={true}
                            rows={4}
                            label='Comment'
                            variant="outlined"
                            color="primary"
                            style={{ width: isMobilePhone ? '90.5%' : '90.5%' }}
                            onChange={(e) => setDescription(e.target.value)}
                            value={description}
                        />
                    </Grid>
                    <Button
                        disabled={acquisitionProcessing}
                        variant="outlined"
                        style={{ marginLeft: 10 }}
                        onClick={(e) => onContinueClick(e, false)}>
                        Order Only
                    </Button>
                    {price !== 0 && <Button
                        disabled={acquisitionProcessing}
                        variant="contained"
                        style={{ marginLeft : 20 }}
                        color="primary"
                        type="submit">
                        Order and Pay
                    </Button>}
                </Grid>
            </form>
            <Box m={2}>
                {acquisitionProcessing ? <CircularProgress /> : null}
                <p style={{ color: Colors.lightRed }}>{errorMessages}</p>
            </Box>
            <HttpResponseMessages />
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        textAlign: 'left',
        borderRadius: 2,
        marginBottom: 0,
    },
}));