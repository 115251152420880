import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { RootState } from 'app/rootReducer';
import appRoutes from 'app/appRoutes';

import Colors from 'Colors';

import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

import CatalogueItems from './CatalogueItems';
import NewListing from './NewListing';
import { setCatalogueItem } from './tradeSlice';
import { setOnBoarding } from 'features/account/userSlice';
import AppAdminHeader from 'app/AppAdminHeader';
import { fetchUserBusiness } from 'features/account/accountApi';
import { Button, createStyles, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Theme, Typography, WithStyles } from '@material-ui/core';

const GlobalCss = withStyles({
  // @global is handled by jss-plugin-global.
  '@global': {
    // You should target [class*="MuiButton-root"] instead if you nest themes.
    '.MuiButton-outlined': {
      color: Colors.ivory,
      borderRadius: 10,
      border: 'solid 2px transparent',
      background: `linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, ${Colors.secondaryColor}, ${Colors.lime})`,
      backgroundOrigin: 'border-box',
      boxShadow: `2px 1000px 1px ${Colors.grey1} inset`
    },
    '.MuiOutlinedInput-root': {
      color: Colors.grey5,
      '& fieldset': {
        borderColor: Colors.ivory,
      },
    },
    '.MuiOutlinedFormControl-root': {
      '& fieldset': {
        borderColor: Colors.secondaryColor,
      },
    },
    '.MuiInputLabel-root': {
      color: Colors.grey5,
    }
  },
})(() => null);

export default function ItemListing() {
  const dispatch = useDispatch();
  const history = useHistory();

  const { catalogueItem } = useSelector(
    (state: RootState) => state.trade
  )

  const { accessToken, isOnBoarding, userProcessing, isAuthenticated, userBusinessProfile, userProcessingError } = useSelector(
    (state: RootState) => state.user
  )

  const [openPopup, setOpenPopup] = useState(isOnBoarding);

  useEffect(() => {
    if (isAuthenticated && accessToken && !userProcessing && !userBusinessProfile && !userProcessingError) {
      dispatch(fetchUserBusiness(accessToken));
    }
    else if(!isAuthenticated || !accessToken)
    {
      history.replace(appRoutes.signIn);
    }
    
    if(isOnBoarding) dispatch(setOnBoarding(false));  
    
  }, [])

  if (isAuthenticated && accessToken && userBusinessProfile && !userBusinessProfile.businessName) {
    history.replace(appRoutes.home);
  }

  const cancelNewListingHandler = () => {
    dispatch(setCatalogueItem(null));
  }

  interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
  }
  const CustomDialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, classes, ...other } = props;
    return (
      <DialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        <IconButton aria-label="close" className={classes.closeButton} onClick={() => setOpenPopup(false)}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
    );
  });

  return (
    <div style={{ minHeight: '100vh' }}>
      <Dialog
        onClose={() => setOpenPopup(false)}
        aria-labelledby="simple-dialog"
        open={openPopup}>
        {/* <CustomDialogTitle id="simple-dialog-title">Payment - Personal Details</CustomDialogTitle> */}
        <DialogContent>
          Welcome on board, KB agent will contact you to assist further with your business acount.
          In a mean time go to KB portal to update your account details or "List Items" to specify your business products or services,
          (please note this is a beta version temporarily providing limited functionality).
          </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            href="https://khona.biz">
            Login to KB Portal
              </Button>
          <Button
            variant="text"
            color="secondary"
            onClick={() => setOpenPopup(false)}>
            List Items
              </Button>
        </DialogActions>
      </Dialog>
      <GlobalCss />
      <AppAdminHeader userDisplayName={userBusinessProfile?.userDisplayName} businessName={userBusinessProfile?.businessName} />
      {!catalogueItem ?
        <CatalogueItems />
        : <NewListing selectedItem={catalogueItem} onCancelNewListing={cancelNewListingHandler} />}
    </div>
  )
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });