import React, { useEffect, useState } from 'react';

import logo from 'img/logo4.png';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { RootState } from 'app/rootReducer';
import appRoutes from 'app/appRoutes';

import { ButtonBase, CircularProgress, createMuiTheme, makeStyles, TextField, ThemeProvider, withStyles } from '@material-ui/core';

import Colors from '../../Colors';

import IDetailedProfile from 'interfaces/IDetailedProfile';
import { fetchSearchCompaniesByKey } from './searchApi';
import { clearProfile, profileFound } from 'features/profile/profileSlice';
import HttpResponseMessages from 'features/errorHandling/HttpResponseMessages';
import { clearErrorMessage } from 'features/errorHandling/errorHandlingSlice';

export default function Search() {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();

    const [selectedResult, setSelectedResult] = useState<IDetailedProfile>();
    const [description, setDesciption] = useState('');
    const [warning, setWarning] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const { searchProcessing, searchResults, searchErrorMessage } = useSelector(
        (state: RootState) => state.search
    )

    const { detailedProfile } = useSelector(
        (state: RootState) => state.profile
    )

    const GlobalCss = withStyles({
        // @global is handled by jss-plugin-global.
        '@global': {
            // You should target [class*="MuiButton-root"] instead if you nest themes.
            '.MuiButton-outlined': {
                color: Colors.ivory,
                borderRadius: 10,
                border: 'solid 2px transparent',
                background: `linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, ${Colors.secondaryColor}, ${Colors.lime})`,
                backgroundOrigin: 'border-box',
                boxShadow: `2px 1000px 1px ${Colors.grey1} inset`
            },
            '.MuiOutlinedInput-root': {
                color: Colors.ivory,
                '& fieldset': {
                    borderColor: Colors.secondaryColor,
                },
            },
            '.MuiOutlinedFormControl-root': {
                '& fieldset': {
                    borderColor: Colors.secondaryColor,
                },
            },
            '.MuiInputLabel-root': {
                color: Colors.ivory,
            }
        },
    })(() => null);

    interface Props {
        company: IDetailedProfile
    }
    const CompanySearchResult = ({ company }: Props) => {
        return (
            <ButtonBase
                className={classes.searchResultContainer}
                onClick={() => onSelectCompanyResult(company)}>
                {company.profile.logo &&
                    <img
                        src={`data:image/jpg;base64,${company.profile.logo}`}
                        className={classes.thumbnail} />}
                <div className={classes.descriptionContainer}>
                    <span className={classes.name}>{company.profile.tradingName}</span>
                    <span className={classes.secondName}>{company.friendlyUserName}</span>
                </div>
            </ButtonBase>
        )
    }

    const onSearchInputKeyPress = (value: string) => {
        dispatch(clearErrorMessage());

        setErrorMessage('');
        setWarning('');
        setDesciption(value);
        if (value.length < 2) return;

        dispatch(fetchSearchCompaniesByKey(value.trim()));
    }

    const onSelectCompanyResult = (selectedCompany: IDetailedProfile) => {
        setSelectedResult(selectedCompany);
        dispatch(clearProfile());
        dispatch(profileFound(selectedCompany));
    }

    useEffect(() => {
        if (selectedResult && detailedProfile) history.push(appRoutes.home);
    });

    return (
        <div style={{ padding: 20 }}>
            <GlobalCss />
            <ButtonBase href="https://khonabiz.com" target="_blank">
                <img
                    src={logo}
                    width="180px" />
            </ButtonBase>
            <div>
                <TextField
                    className={classes.inputs}
                    label='Search'
                    variant="outlined"
                    color="primary"
                    onChange={(e) => onSearchInputKeyPress(e.target.value)}
                    value={description}
                />
            </div>
            <p className={classes.instructions} style={{ marginTop: 0, marginBottom: 20 }}>Search by name, username or company pin</p>
            {searchResults && searchResults.length > 0 && <p className={classes.instructions}>Select results below</p>}
            <div>
                {searchResults && searchResults.map((item, index) => (
                    <CompanySearchResult key={index}
                        company={item}
                    />
                ))
                }
            </div>
            {searchProcessing && <CircularProgress />}
            <p>{warning}</p>
            <p>{errorMessage}</p>
            <HttpResponseMessages />
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    inputs: {
        maxWidth: 400,
        width: '100%',
        height: 72,
        borderColor: Colors.grey3,
        borderRadius: 5,
        borderWidth: 1,
        textAlign: 'center',
        fontSize: 18,
        color: Colors.grey3,
        marginTop: theme.spacing(4)
    },
    instructions: {
        marginRight: 20,
        marginLeft: 20,
        color: Colors.grey4,
        fontSize: 12
    },
    searchResultContainer: {
        width: 250,
        height: 55,
        backgroundColor: Colors.ivory,
        borderColor: Colors.grey5,
        borderWidth: 1,
        borderRadius: 5,
        padding: 5,
        margin: 5,
    },
    descriptionContainer: {
        flex: 1,
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        marginLeft: 10,
    },
    thumbnail: {
        margin: 5,
        width: 55,
        height: 32,
        objectFit: 'contain',
    },
    name: {
        color: Colors.grey1,
        fontSize: 13,
        fontWeight: 'bold',
        marginRight: theme.spacing(1)
    },
    secondName: {
        color: Colors.grey3,
        fontSize: 12,
    }
}));