export default class EnquiryModel {
    visitId: string;
    enquireAbout: string;
    visitorName: string;
    visitorContacts: string;
    visitorEmail: string;
    message: string;

    constructor(visit: string, enquireAbout: string, visistorName: string, visitorContacts: string, visitorEmail: string, message: string){
        this.visitId = visit;
        this.enquireAbout = enquireAbout;
        this.visitorName = visistorName;
        this.visitorContacts = visitorContacts;
        this.visitorEmail = visitorEmail;
        this.message = message;
    }
}