/* eslint-disable no-use-before-define */
import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { FormControl, FormHelperText, Select, MenuItem } from '@material-ui/core';

import Colors from 'Colors';

import IItemCategory from 'interfaces/IItemCategory';
import IBusinessType from 'interfaces/IBusinessType';
import ItemCategoryModel from 'models/ItemCategoryModel';
import BusinessType from 'models/BusinessTypeModel';

const filter = createFilterOptions<IItemCategory>();

interface Props {
    categories: Array<IItemCategory>,
    businessTypes: Array<IBusinessType>,
    onSelectedCategory: (item: IItemCategory) => void,
}
export default function FreeSoloCreateOptionDialog({ categories, businessTypes, onSelectedCategory }: Props) {
  const [value, setValue] = useState<IItemCategory | null>(null);
  const [open, toggleOpen] = useState(false);

  const [businessTypeId, setBusinessTypeId] = useState(0);
  const [newBusinessType, setNewBusinessType] = useState("");
  const [categoryDescription, setCategoryDescription] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleClose = () => {
    setBusinessTypeId(-1);
    setCategoryDescription("");

    toggleOpen(false);
  };

  const handleSubmitNewCategory = () => { //event: React.FormEvent<HTMLFormElement>   
    if(businessTypeId === 0){
      setErrorMessage("Please select Business Type or 'Other' to specify");
      return;
    }
    else if(businessTypeId === -1 && !newBusinessType){
      setErrorMessage("Please specify new Business Type");
      return;
    }
    if(!categoryDescription){
      setErrorMessage("Please specify new Category short description");
      return;
    }

    const businessType = businessTypeId === -1 ?
      new BusinessType(newBusinessType.trim())
    : businessTypes.filter(item => {
      return item.id === businessTypeId
    })[0];

    var newItem = new ItemCategoryModel(categoryDescription.trim(), businessType);
    setValue(newItem);
    onSelectedCategory(newItem);
    
    handleClose();
  };

  return (
    <React.Fragment>
      <Autocomplete
        value={value}
        onChange={(event, newValue) => {
            if (typeof newValue === 'string') {
                // timeout to avoid instant validation of the dialog's form.
                setTimeout(() => {
                    toggleOpen(true);
                    setCategoryDescription(newValue);
                });
            }
            else if (newValue && !newValue.businessType) {
                toggleOpen(true);
                let valueIndex = newValue.description.indexOf(":") + 2;
                setCategoryDescription(newValue.description.substr(valueIndex))
            } 
            else {
                setValue(newValue);
                if(newValue) onSelectedCategory(newValue);
            }
        }}
        filterOptions={(options, params) => {
            const filtered = filter(options, params) as IItemCategory[];

            if (params.inputValue !== '') {
                filtered.push(new ItemCategoryModel(`Select to Add: ${params.inputValue}`, null));
            }

            return filtered;
        }}
        id="free-solo-dialog-demo"
        options={categories}
        getOptionLabel={(option) => {
            // e.g value selected with enter, right from the input
            if (typeof option === 'string') {
                return option;
            }
            /*if (option.inputValue) {
                return option.inputValue;
            }*/
            return option.description;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        renderOption={(option) => option.description}
        style={{ width: 300 }}
        freeSolo
        renderInput={(params) => (
          <TextField {...params} label="Category" variant="outlined" />
        )}
      />
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <form>
          <DialogTitle id="form-dialog-title">Add Category</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Did you miss any Category in our list? Please, add it!
            </DialogContentText>
            <div style={{ display: 'flex', flexDirection: 'column' }}>            
              <FormControl>
                <FormHelperText style={{ color: 'lightgray', fontSize: 13, marginLeft: 0 }}>Business Type / Industry</FormHelperText>
                  <Select
                      value={businessTypeId}
                      onChange={e => setBusinessTypeId(parseInt(e.target.value as string))}>
                        <MenuItem value={0}></MenuItem>
                        <MenuItem value={-1}>Other (specify)</MenuItem>
                        {businessTypes ? businessTypes.map(item => (
                            <MenuItem value={item.id}>{item.description}</MenuItem>
                          ))
                        : null}
                  </Select>
              </FormControl>
              {businessTypeId === -1 ? <TextField
                margin="dense"
                value={newBusinessType}
                onChange={(event) => setNewBusinessType(event.target.value)}
                label="New Business Type"
              /> : null}
              <TextField
                margin="dense"
                value={categoryDescription}
                onChange={(event) => setCategoryDescription(event.target.value)}
                label="Description"
              />
              <p style={{ color: Colors.maroon }}>{errorMessage}</p> 
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSubmitNewCategory} variant="outlined" color="primary">
              Add
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
}