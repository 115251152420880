import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ICompanyProfileState {
  companyProfileProcessing: boolean;
  companyProfileCreatedSuccessfully: boolean;
  detailedProfileSubmittedSuccessfully: boolean;
  companyBannerUploadProcessing: boolean;
  companyBannerUploadedSuccessfully: boolean;
  companyProfileId: string | null;
  companyProfileProcessingError: string;
}

let initialState : ICompanyProfileState = {
  companyProfileProcessing: false,
  companyProfileCreatedSuccessfully: false,
  detailedProfileSubmittedSuccessfully: false,
  companyBannerUploadProcessing: false,
  companyBannerUploadedSuccessfully: false,
  companyProfileId: null,
  companyProfileProcessingError: ""   
}

const companyProfileSlice = createSlice({
    name: 'companyAccount',
    initialState,
    reducers: {
        companyProfileProcessingStart(state) {
          state.companyProfileProcessing = true; 
          state.companyProfileProcessingError = "";
        },
        setCompanyProfileSumittedSuccessfully(state, { payload }: PayloadAction<string>) {
          state.companyProfileProcessing = false;
          state.companyProfileCreatedSuccessfully = true; 
          state.companyProfileId = payload;
          state.companyProfileProcessingError = "";
        },
        setDetailedProfileSubmittedSuccessfully(state) {
          state.companyProfileProcessing = false; 
          state.detailedProfileSubmittedSuccessfully = true;
          state.companyProfileProcessingError = "";
        },
        companyBannerUploadStart(state) {
          state.companyBannerUploadProcessing = true; 
        },
        setCompanyBannerUploadedSuccessfully(state) {
          state.companyProfileProcessing = false; 
          state.companyBannerUploadProcessing = false;
          state.companyBannerUploadedSuccessfully = true;
          state.companyProfileProcessingError = "";
        },
        companyProfileProcessingError(state, { payload }: PayloadAction<string>) {
          state.companyProfileProcessing = false;
          state.companyBannerUploadProcessing = false; 
          state.companyProfileProcessingError = payload;
        }
    }
});

export const {
    companyProfileProcessingStart,
    setCompanyProfileSumittedSuccessfully,
    setDetailedProfileSubmittedSuccessfully,
    companyBannerUploadStart,
    setCompanyBannerUploadedSuccessfully,
    companyProfileProcessingError,
  } = companyProfileSlice.actions

export default companyProfileSlice.reducer;