import IUserProfile from 'interfaces/IUserProfile';

export default class UserProfileModel implements IUserProfile {
    name: string;
    surname: string;
    email: string;
    contacts: string;

    constructor(name: string, surname: string, emailAddress: string, contacts: string){
        this.name = name;
        this.surname = surname;
        this.email = emailAddress;
        this.contacts = contacts;
    }
} 