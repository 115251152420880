import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'app/rootReducer';

import { makeStyles } from '@material-ui/core/styles';
import { Select, MenuItem, TextField, FormControl, FormHelperText, Typography, Button, CircularProgress } from '@material-ui/core';

import Colors from 'Colors';

import EnquiryModel from 'models/EnquiryModel';
import { submitEnquiry } from './tradeApi';
import HttpResponseMessages from 'features/errorHandling/HttpResponseMessages';
import { clearErrorMessage } from 'features/errorHandling/errorHandlingSlice';

interface Props {
    heading: string,
    visitId: string,
    services: Array<string>,
    isMobilePhone: boolean
}
export default function Enquiry({ heading, visitId, services, isMobilePhone } : Props) {
    const dispatch = useDispatch();
    const classes = useStyles();
        
    const [enquireAbout, setEnquireAbout] = useState("General");
    const [visitorName, setVisitorName] = useState("");
    const [visitorContacts, setVisitorContacts] = useState("");
    const [visitorEmail, setVisitorEmail] = useState("");
    const [message, setMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    
    useEffect(() => {  
    });
    
    const { enquiryProcessing, enquirySubmittedSuccessfully, enquiryErrorMessage } = useSelector(
        (state: RootState) => state.trade
    )

    const onSubmitClick = (e: any) => {
        e.preventDefault();        
        dispatch(clearErrorMessage());
        setErrorMessage("");
        //Validate
        if(!visitorName){
            setErrorMessage("Name not specified.");
            return;
        }
        else if(!visitorEmail && !visitorContacts){
            setErrorMessage("You need to specify email or contacts to respond to.");
            return;
        }
        else if(!message){
            setErrorMessage("Message not entered.")
            return;
        }
        
        if(visitorEmail){
            var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
            if(!mailformat.test(visitorEmail)) {
                setErrorMessage("Invalid Email.");
                return;
            }
        }
        if(visitorContacts){
            if(visitorContacts.length < 9 || visitorContacts.length > 20) {
                setErrorMessage("Invalid Contacts.");
                return;
            }
        }

        var model = new EnquiryModel(visitId, enquireAbout, visitorName.trim(), visitorContacts, visitorEmail, message.trim());

        dispatch(submitEnquiry(model));
    }
   
    return(
        <div>
            <p className="Sub-header" style={{ color: Colors.ivory }}>{heading ? heading : "Enquire"}</p>
            <form onSubmit={onSubmitClick} className={classes.container}>
                <div className={`Form-input-container${isMobilePhone ? "-mobile" : ""}`}>
                    <FormControl variant="outlined">
                        <Select
                            className={`Input${isMobilePhone ? "-mobile" : ""}`}
                            value={enquireAbout}
                            onChange={e => setEnquireAbout(e.target.value as string)} >
                            <MenuItem value={"General"}>General</MenuItem>                        
                            {services.map((item, index) => (
                                <MenuItem key={index} value={item}>{item}</MenuItem>  
                            ))}
                        </Select>
                        <FormHelperText style={{ color: 'lightgray' }}>Enquire About?</FormHelperText>
                    </FormControl>
                    <TextField
                        label='Your Name'
                        variant="outlined"
                        className={`Input${isMobilePhone ? "-mobile" : ""}`}
                        onChange={(e) => setVisitorName(e.target.value)}
                        value={visitorName}
                    />
                </div>
                <div className={`Form-input-container${isMobilePhone ? "-mobile" : ""}`}> 
                    <TextField
                        label='Email'
                        variant="outlined"
                        color="primary"
                        className={`Input${isMobilePhone ? "-mobile" : ""}`}
                        onChange={(e) => setVisitorEmail(e.target.value.trim())}
                        value={visitorEmail}
                    />
                    <TextField
                        label='Contact Numbers'
                        variant="outlined"
                        color="primary"
                        className={`Input${isMobilePhone ? "-mobile" : ""}`}
                        onChange={(e) => setVisitorContacts(e.target.value.trim())}
                        value={visitorContacts}
                    />
                </div>
                <div className={`Form-input-container${isMobilePhone ? "-mobile" : ""}`}>
                    <TextField
                        multiline={true}
                        rows={3}
                        label='Message'
                        variant="outlined"
                        color="primary"
                        className={`Input${isMobilePhone ? "-mobile" : ""}`}
                        style={{ width: '90%', maxWidth: 450, margin: 0 }}
                        onChange={(e) => setMessage(e.target.value)}
                        value={message}
                    />
                </div>
                <Button
                    disabled={enquiryProcessing || enquirySubmittedSuccessfully}
                    variant="outlined"
                    className={`Button${isMobilePhone ? "-mobile" : ""}`}
                    type="submit">
                    Submit
                </Button>
            </form>   
            {enquiryProcessing ? <CircularProgress /> : null}               
            <p style={{ color: Colors.lightRed }}>{errorMessage}</p>       
            <HttpResponseMessages showInPopup={true} />  
            {enquirySubmittedSuccessfully ? 
                <Typography color="primary">Enquiry Submitted Successfully</Typography> 
                : null 
            }                          
        </div>
    )    
}

const useStyles = makeStyles((theme) => ({
    container: {
      width: '100%',
      color: Colors.ivory,
      textAlign: 'center',
      borderRadius: 2,
      marginBottom: 0,
    }
}));