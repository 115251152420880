import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from 'app/rootReducer';

import { Button, CircularProgress, createStyles, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField, Theme, Typography, WithStyles, withStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

import Colors from 'Colors';
import { signIn } from './accountApi';
import { setOnBoarding, signOut } from './userSlice';
import OnBoardingMainView from './OnBoardingMainView';
import HttpResponseMessages from 'features/errorHandling/HttpResponseMessages';
import { clearErrorMessage } from 'features/errorHandling/errorHandlingSlice';
import appRoutes from 'app/appRoutes';
import { Alert } from '@material-ui/lab';

interface Props {
    isMobilePhone: boolean
}
export default function SignIn({ isMobilePhone }: Props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    let history = useHistory();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [openPopup, setOpenPopup] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const { userProcessing, isOnBoarding, isAuthenticated, accessToken, userBusinessProfile } = useSelector(
        (state: RootState) => state.user
    )

    const detailedProfile = useSelector(
        (state: RootState) => state.profile.detailedProfile
    )

    const acquisition = useSelector(
        (state: RootState) => state.trade.acquisition
    )

    useEffect(() => {
        if ((isAuthenticated || accessToken) && !userProcessing) dispatch(signOut());

        if (isOnBoarding) dispatch(setOnBoarding(false));

        // if ((!isAuthenticated || !accessToken) && acquisition && detailedProfile && !openPopup)
            // setOpenPopup(true);
    }, [])

    if (isAuthenticated && accessToken && acquisition) {
        history.replace(appRoutes.pay);
    }
    /* else if (isAuthenticated && accessToken && userBusinessProfile && !userBusinessProfile.businessName) {
        history.replace(appRoutes.home);
    } */
    else if (isAuthenticated && accessToken) {
        history.replace(appRoutes.listItems);
    }

    const onSignin = (e: any) => {
        e.preventDefault();
        setErrorMessage("");
        dispatch(clearErrorMessage());
        //Validate
        if (!email) {
            setErrorMessage("Email not specify.");
            return;
        }
        else if (!password) {
            setErrorMessage("Password not entered.")
            return;
        }

        dispatch(signIn(email, password));
    }

    interface DialogTitleProps extends WithStyles<typeof styles> {
        id: string;
        children: React.ReactNode;
    }
    const CustomDialogTitle = withStyles(styles)((props: DialogTitleProps) => {
        const { children, classes, ...other } = props;
        return (
            <DialogTitle disableTypography className={classes.root} {...other}>
                <Typography variant="h6">{children}</Typography>
                <IconButton aria-label="close" className={classes.closeButton} onClick={() => setOpenPopup(false)}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
        );
    });

    return (
        <OnBoardingMainView isMobilePhone={isMobilePhone} heading="Sign in">
            <Dialog
                onClose={() => setOpenPopup(false)}
                aria-labelledby="simple-dialog"
                open={openPopup}>
                <CustomDialogTitle id="simple-dialog-title">Payment - Personal Details</CustomDialogTitle>
                <DialogContent>
                    <strong>{detailedProfile?.friendlyUserName} </strong>
                    would like your personal details regarding your payment, sign-in
                    if you have KhonaBiz account otherwise Create Account
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="text"
                        onClick={() => history.push(appRoutes.signUp)}>
                        Create Account
                    </Button>
                    <Button
                        variant="text"
                        color="secondary"
                        onClick={() => setOpenPopup(false)}>
                        Sign in
                    </Button>
                </DialogActions>
            </Dialog>
            <p className="Sub-header" style={{ color: Colors.grey1 }}></p>
            <form onSubmit={onSignin} className={classes.container}>
                <TextField
                    label='Email'
                    variant="outlined"
                    className={classes.input}
                    onChange={(e) => setEmail(e.target.value.trim())}
                    value={email}
                />
                <TextField
                    label='Password'
                    variant="outlined"
                    type="password"
                    className={classes.input}
                    onChange={(e) => setPassword(e.target.value.trim())}
                    value={password}
                />
                <Button
                    disabled={userProcessing}
                    variant="outlined"
                    className={classes.button}
                    type="submit">
                    Sign in
                </Button>
                {userProcessing ? <CircularProgress color="secondary" /> : null}
                <p className={classes.errorMessage}>{errorMessage}</p>
                <Alert severity="info">
                    <div>Forgot Password?</div>
                    <div>use <a href="https://khona.biz/forgot-password" target="_blank">KB Portal to reset password</a></div>
                </Alert>
                <HttpResponseMessages />
                <p style={{ paddingTop: 10, fontSize: 15 }}>Don't have a KhonaBiz account?</p>
                <Button
                    disabled={userProcessing}
                    variant="contained"
                    color="default"
                    className={classes.button}
                    onClick={() => history.push(appRoutes.signUp)}>
                    Sign Up
                </Button>
            </form>
        </OnBoardingMainView>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        color: Colors.ivory,
        alignItems: 'center',
        borderRadius: 2,
        marginBottom: 0,
    },
    input: {
        width: 222,
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(1 * 0.5),
        marginRight: theme.spacing(1 * 0.5)
    },
    button: {
        width: 150,
        margin: theme.spacing(2),
    },
    errorMessage: {
        color: Colors.maroon,
        fontSize: 15,
        padding: theme.spacing(1)
    },
    dialog: {
        backgroundColor: Colors.grey5,
        opacity: 0.7,
        color: Colors.grey1,
        padding: theme.spacing(1)
    }
}));

const styles = (theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: theme.spacing(2),
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
    });