import IAcquisition from 'interfaces/IAcquisition';

export default class AcquisitionModel implements IAcquisition{
    item: string;
    type: number;
    offer: number;
    quantity: number;
    comment: string;
    date: Date;
    estimateCompletionDate: Date;
    sendTo: string;

    constructor(item: string, type: number, comment: string, offer: number, quantity: number, date: Date, sendTo: string){
        this.item = item;
        this.type = type;
        this.offer = offer
        this.quantity = quantity;
        this.comment = comment
        this.date = new Date();
        this.estimateCompletionDate = date;
        this.sendTo = sendTo;
    }
}