import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'app/rootReducer';
import appRoutes from 'app/appRoutes';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import { TextField, Button, CircularProgress } from '@material-ui/core';

import dpPlaceholder from 'img/dp-placeholder2.png';

import Colors from 'Colors';

import OnBoardingMainView from './OnBoardingMainView';

import CompanyProfileModel from 'models/CompanyProfileModel';
import { submitCompanyProfile } from './accountApi';
import { openPopUpMessage } from 'app/appSlice';
import HttpResponseMessages from 'features/errorHandling/HttpResponseMessages';
import { clearErrorMessage } from 'features/errorHandling/errorHandlingSlice';

interface Props {
  isMobilePhone: boolean
}

export default function CreateCompany({ isMobilePhone }: Props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  let history = useHistory();

  const [displayPicture, setDisplayPicture] = useState("");
  const [uploadPicture, setUploadPicture] = useState<any>({});
  const [tradingName, setTradingName] = useState("");
  const [email, setEmail] = useState("");
  const [contacts, setContacts] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const { accessToken, isAuthenticated, userProfile } = useSelector(
    (state: RootState) => state.user
  )

  const showPopupMessage = useSelector(
    (state: RootState) => state.app.showPopupMessage
  )

  const { companyProfileProcessing, companyProfileCreatedSuccessfully, companyProfileProcessingError } = useSelector(
    (state: RootState) => state.companyProfile
  )

  useEffect(() => {
    if (userProfile && !tradingName) {
      setTradingName(`${userProfile.name} ${userProfile.surname}`);
      setEmail(userProfile.email);
      setContacts(userProfile.contacts);
    }
  })

  const onFileSelected = (event: any) => {
    if (!event.target) return;

    const uploadedMedia = event.target.files[0];

    //display picture      
    var fileReader = new FileReader();
    fileReader.onload = (e) => {
      if (e.target) setDisplayPicture(e.target.result as string);
    };
    fileReader.readAsDataURL(uploadedMedia);

    setUploadPicture(uploadedMedia)
  }

  const onSubmitClick = (e: any) => {
    e.preventDefault();
    dispatch(clearErrorMessage());
    setErrorMessage("");
    //Validate
    if (!uploadPicture.name) {
      setErrorMessage("Your business professional display picture or logo must be uploaded for public profile");
      return;
    }
    else if (!tradingName) {
      setErrorMessage("Trading Name not specified.");
      return;
    }
    else if (!email) {
      setErrorMessage("Email not specify.");
      return;
    }

    if (email) {
      var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (!mailformat.test(email)) {
        setErrorMessage("Invalid Email.");
        return;
      }
    }

    if (contacts) {
      if (contacts.length < 9 || contacts.length > 20) {
        setErrorMessage("Invalid Contacts.");
        return;
      }
    }

    const model = new CompanyProfileModel(tradingName.trim(), email, contacts);

    dispatch(submitCompanyProfile(accessToken, uploadPicture, model));
  }

  if (!companyProfileProcessing && companyProfileCreatedSuccessfully && !showPopupMessage) {
    dispatch(openPopUpMessage("Business Account has been created."));
    history.replace(appRoutes.detailedProfile);
  }

  return (
    <OnBoardingMainView isMobilePhone={isMobilePhone} heading="Trading Profile">
      <p className="Sub-header" style={{ color: Colors.grey1 }}></p>
      <form onSubmit={onSubmitClick} className={classes.container}>
        <img src={displayPicture ? displayPicture : dpPlaceholder} className={classes.displayPicture} alt="" />
        <input
          type="file"
          accept="image/gif, image/jpeg, image/jpg, image/png"
          className={classes.fileInput}
          onChange={onFileSelected}
        />
        <TextField
          label='Trading Name'
          variant="outlined"
          className={classes.input}
          onChange={(e) => setTradingName(e.target.value)}
          value={tradingName}
        />
        <TextField
          label='Business Email'
          variant="outlined"
          className={classes.input}
          onChange={(e) => setEmail(e.target.value.trim())}
          value={email}
        />
        <TextField
          label='Contact Numbers'
          variant="outlined"
          className={classes.input}
          onChange={(e) => setContacts(e.target.value.trim())}
          value={contacts}
        />
        <Button
          disabled={companyProfileProcessing}
          variant="outlined"
          className={classes.button}
          type="submit">
          Submit
        </Button>
        {companyProfileProcessing ? <CircularProgress color="secondary" /> : null}
        <p className={classes.errorMessage}>{errorMessage}</p>
        <HttpResponseMessages showInPopup={true} />
      </form>
    </OnBoardingMainView>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    color: Colors.ivory,
    alignItems: 'center',
    borderRadius: 2,
    marginBottom: 0,
  },
  input: {
    width: 222,
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1 * 0.5),
    marginRight: theme.spacing(1 * 0.5)
  },
  fileInput: {
    width: 222,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(1 * 0.5),
    marginRight: theme.spacing(1 * 0.5)
  },
  displayPicture: {
    height: '20vmin',
    width: '20vmin',
    borderRadius: 100,
    objectFit: 'contain',
    backgroundColor: Colors.black,
    margin: 5,
  },
  button: {
    width: 150,
    margin: theme.spacing(2),
  },
  errorMessage: {
    color: Colors.maroon,
    fontSize: 15,
    padding: theme.spacing(1)
  }
}));