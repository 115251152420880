import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'app/rootReducer';
import appRoutes from 'app/appRoutes';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import { TextField, Button, CircularProgress } from '@material-ui/core';

import Colors from 'Colors';

import OnBoardingMainView from './OnBoardingMainView';

import UserModel from 'models/User';
import UserProfileModel from 'models/UserProfile';
import { registerUser, submitUserProfile } from './accountApi';
import { openPopUpMessage } from 'app/appSlice'
import { setOnBoarding } from './userSlice';
import PasswordStrengthIndicator from './PasswordStrengthIndicator';
import HttpResponseMessages from 'features/errorHandling/HttpResponseMessages';
import { clearErrorMessage } from 'features/errorHandling/errorHandlingSlice';

interface Props {
    isMobilePhone: boolean
}
export default function RegisterUser({ isMobilePhone }: Props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    let history = useHistory();

    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [isPasswordValid, setIsPasswordValid] = useState(false);
    const [contacts, setContacts] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const showPopupMessage = useSelector(
        (state: RootState) => state.app.showPopupMessage
    )

    const { userProcessing, isOnBoarding, userProcessingError, userRegisteredSuccessfully, userProfileCreatedSuccessfully, accessToken } = useSelector(
        (state: RootState) => state.user
    )

    const acquisition = useSelector(
        (state: RootState) => state.trade.acquisition
    )

    useEffect(() => {
        if (!isOnBoarding) dispatch(setOnBoarding(true));
    })

    const onSubmitClick = (e: any) => {
        e.preventDefault();
        dispatch(clearErrorMessage());
        setErrorMessage("");
        //Validate
        if (!name) {
            setErrorMessage("Name not specified.");
            return;
        }
        if (!surname) {
            setErrorMessage("Surname not specified.");
            return;
        }
        else if (!email) {
            setErrorMessage("Email not specify.");
            return;
        }
        else if (!password) {
            setErrorMessage("Password not entered.");
            return;
        }
        else if (!confirmPassword) {
            setErrorMessage("Please Confirm Password.");
            return;
        }
        else if (!isPasswordValid) {
            setErrorMessage("Password invalid. Must be minimum of 8 characters, have capital letter, small letter, numbers and one of these special characters i.e. Pass123$");
            return;
        }
        else if (password == 'Pass123$') {
            setErrorMessage("You cannot use sample password 'Pass123$'")
            return;
        }
        else if (password !== confirmPassword) {
            setErrorMessage("Password and Confirm Password not the same!");
            return;
        }

        if (email) {
            var mailformat = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            ///^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;            
            if (!mailformat.test(email)) {
                setErrorMessage("Invalid Email.");
                return;
            }
        }

        if (contacts) {
            if (contacts.length < 9 || contacts.length > 20) {
                setErrorMessage("Invalid Contacts.");
                return;
            }
        }

        const model = new UserModel(email, password);

        dispatch(registerUser(model));
    }

    if (userRegisteredSuccessfully && accessToken && !userProfileCreatedSuccessfully && !userProcessing) {
        const model = new UserProfileModel(name.trim(), surname.trim(), email, contacts);

        dispatch(submitUserProfile(accessToken, model));
    }

    if (!userProcessing && userRegisteredSuccessfully && userProfileCreatedSuccessfully && !showPopupMessage) {
        dispatch(openPopUpMessage("Your Account has been created!"));
        if (!acquisition) {
            history.replace(appRoutes.createCompany);
        }
        else {
            history.replace(appRoutes.pay);
        }
    }

    return (
        <OnBoardingMainView isMobilePhone={isMobilePhone} heading="Sign up">
            <p className="Sub-header" style={{ color: Colors.grey1 }}></p>
            <form onSubmit={onSubmitClick} className={classes.container}>
                <TextField
                    label='Your Name'
                    variant="outlined"
                    className={classes.input}
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                />
                <TextField
                    label='Surname'
                    variant="outlined"
                    className={classes.input}
                    onChange={(e) => setSurname(e.target.value)}
                    value={surname}
                />
                <TextField
                    label='Email'
                    variant="outlined"
                    className={classes.input}
                    onChange={(e) => setEmail(e.target.value.trim())}
                    value={email}
                />
                <TextField
                    label='Password'
                    variant="outlined"
                    type="password"
                    autoComplete="new-password"
                    className={classes.input}
                    onChange={(e) => setPassword(e.target.value.trim())}
                    value={password}
                />
                <PasswordStrengthIndicator password={password} setIsPasswordValid={(isValid) => setIsPasswordValid(isValid)} />
                {password ? <TextField
                    label='Confirm Password'
                    variant="outlined"
                    type="password"
                    className={classes.input}
                    onChange={(e) => setConfirmPassword(e.target.value.trim())}
                    value={confirmPassword}
                /> : null}
                <TextField
                    label='Contact Numbers'
                    variant="outlined"
                    className={classes.input}
                    onChange={(e) => setContacts(e.target.value.trim())}
                    value={contacts}
                />
                <Button
                    disabled={userProcessing}
                    variant="outlined"
                    className={classes.button}
                    type="submit">
                    Submit
                </Button>
                {userProcessing ? <CircularProgress color="secondary" /> : null}
                <p className={classes.errorMessage}>{errorMessage}</p>
                <HttpResponseMessages showInPopup={true} />
                <Button
                    disabled={userProcessing}
                    variant="text"
                    onClick={() => history.push(appRoutes.signIn)}>
                    Already have an account? Sign-in
                </Button>
            </form>
        </OnBoardingMainView>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        color: Colors.ivory,
        alignItems: 'center',
        borderRadius: 2,
        marginBottom: 0,
    },
    input: {
        width: 222,
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(1 * 0.5),
        marginRight: theme.spacing(1 * 0.5)
    },
    button: {
        width: 150,
        margin: theme.spacing(2),
    },
    errorMessage: {
        color: Colors.maroon,
        fontSize: 15,
        margin: 0
    }
}));