import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IPaymentState {
    paymentProcessing: boolean;
    paymentCompletedSuccessfully: boolean;
    paymentResponseHtml: string;
    paymentProcessingError: string;
}

let initialState: IPaymentState = {
    paymentProcessing: false,
    paymentCompletedSuccessfully: false,
    paymentResponseHtml: "",
    paymentProcessingError: ""
}

const paymentSlice = createSlice ({
    name: 'payment',
    initialState,
    reducers: {
        paymentProccesingStart(state){
            state.paymentProcessing = true;
        },
        set3DAuthorise(state, { payload }: PayloadAction<string>){
            state.paymentResponseHtml = payload;
            state.paymentProcessing = false;
        },
        setPaymentCompletedSuccessfully(state){
            state.paymentCompletedSuccessfully = true;
            state.paymentProcessing = false;
        },
        setPaymentProcessingError(state, { payload }: PayloadAction<string>) {
            state.paymentProcessingError = payload;
            state.paymentProcessing = false;
        }
    }
});

export const {
    paymentProccesingStart,
    setPaymentCompletedSuccessfully,
    set3DAuthorise,
    setPaymentProcessingError
} = paymentSlice.actions;

export default paymentSlice.reducer;