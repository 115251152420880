import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'app/rootReducer';

import { Button, CircularProgress, RadioGroup, Radio, FormControl, FormControlLabel, FormHelperText, TextField, Typography, makeStyles } from '@material-ui/core';

import dpPlaceholder from 'img/dp-placeholder2.png';

import Colors from 'Colors';

import ICatalogueItem from 'interfaces/ICatalogueItem';
import ListingModel from 'models/ListingModel';
import { submitListing, uploadListingPicture } from './tradeApi';
import { setCatalogueItem } from './tradeSlice';
import HttpResponseMessages from 'features/errorHandling/HttpResponseMessages';
import { clearErrorMessage } from 'features/errorHandling/errorHandlingSlice';

interface Props {
    selectedItem: ICatalogueItem,
    onCancelNewListing: () => void
}
export default function NewListing({ selectedItem, onCancelNewListing }: Props) {
    const classes = useStyles();

    const dispatch = useDispatch();

    const [displayPicture, setDisplayPicture] = useState("");
    const [uploadPicture, setUploadPicture] = useState({});
    const [selectedType, setSelectedType] = useState<number>(0);
    const [alias, setAlias] = useState(`${selectedItem.category} - ${selectedItem.description}`);
    const [description, setDescription] = useState("");
    const [price, setPrice] = useState(0);
    const [inStock] = useState(true);
    const [isAvailable] = useState(true);
    const [errorMessage, setErrorMessage] = useState("");

    const { accessToken } = useSelector(
        (state: RootState) => state.user
    )

    const { loadTradeProcessing, tradeErrorMessage, listingId, listingPictureUploaded } = useSelector(
        (state: RootState) => state.trade
    )

    const userBusinessProfile = useSelector(
        (state: RootState) => state.user.userBusinessProfile
    )

    const onFileSelected = (e: any) => {
        if (!e.target) return;

        const uploadedMedia = e.target.files[0];

        //display picture      
        var fileReader = new FileReader();
        fileReader.onload = (e) => {
            if (e.target) setDisplayPicture(e.target.result as string);
        };

        fileReader.readAsDataURL(uploadedMedia);

        setUploadPicture(uploadedMedia)
    }

    const onSubmitClick = (e: any) => {
        e.preventDefault();
        dispatch(clearErrorMessage());
        setErrorMessage("");

        if (!selectedType) {
            setErrorMessage("Please select Type (Produt/Service)");
            return;
        }
        if (!alias) {
            setErrorMessage("Name/Title not specified.");
            return;
        }

        if (userBusinessProfile) {
            const model = new ListingModel(userBusinessProfile.companyPin, selectedType, selectedItem.id, alias.trim(), description.trim(), price);
            dispatch(submitListing(accessToken, model));
        }
        else{
            setErrorMessage("Company not found");
            return;
        }
    }

    if (listingId && !listingPictureUploaded && !loadTradeProcessing && !tradeErrorMessage)
        dispatch(uploadListingPicture(accessToken, listingId, uploadPicture));

    if (listingId && listingPictureUploaded && !loadTradeProcessing && !tradeErrorMessage)
        dispatch(setCatalogueItem(null));
        
    return (
        <form onSubmit={onSubmitClick} className={classes.container}>
            <h3 className={classes.heading}>New Product/Service Listing</h3>
            <div className={classes.inputContainer}>
                <img src={displayPicture ? displayPicture : dpPlaceholder} className={classes.displayPicture} alt="" />
                <FormControl className={classes.uploadPictureContainer}>
                    <input
                        type="file"
                        accept="image/gif, image/jpeg, image/jpg, image/png"
                        className={classes.fileInput}
                        onChange={onFileSelected}
                    />
                    <FormHelperText style={{ color: 'lightgray' }}>Upload item quality picture</FormHelperText>
                </FormControl>
            </div>
            <div><h4>{`${selectedItem.category} - ${selectedItem.description}`}</h4></div>
            <FormControl variant="outlined">
                <RadioGroup row
                    style={{ justifyContent: 'center' }}
                    value={selectedType}
                    onChange={e => setSelectedType(parseInt(e.target.value))} >
                    <FormControlLabel value={1} control={<Radio />} label="Product" />
                    <FormControlLabel value={2} control={<Radio />} label="Service" />
                </RadioGroup>
            </FormControl>
            <div className={classes.inputContainer}>
                <FormControl>
                    <TextField
                        label='Title/Name'
                        variant="outlined"
                        className={classes.input}
                        onChange={(e) => setAlias(e.target.value)}
                        value={alias}
                    />
                    <FormHelperText className={classes.helperText}>to appear as listing heading</FormHelperText>
                </FormControl>
                <TextField
                    label='Price'
                    type="number"
                    variant="outlined"
                    color="primary"
                    className={classes.input}
                    onChange={(e) => setPrice(e.target.value.trim() as any)}
                    value={price}
                />
            </div>
            <div className={classes.inputContainer}>
                <TextField
                    multiline={true}
                    rows={3}
                    label='Description'
                    variant="outlined"
                    color="primary"
                    className={classes.textareaInput}
                    onChange={(e) => setDescription(e.target.value)}
                    value={description}
                />
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Button
                    disabled={loadTradeProcessing}
                    className={classes.cancelButton}
                    variant="text"
                    onClick={onCancelNewListing}>
                    Cancel
                </Button>
                <Button
                    disabled={loadTradeProcessing}
                    className={classes.button}
                    variant="outlined"
                    type="submit">
                    Submit
                </Button>
            </div>
            {loadTradeProcessing ? <CircularProgress /> : null}
            <p style={{ color: Colors.orangeRed }}>{errorMessage}</p>
            <HttpResponseMessages />
            {listingId ?
                <Typography color="primary">Listing Submitted Successfully</Typography>
                : null
            }
        </form>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        color: Colors.grey5,
        textAlign: 'center',
        alignItems: 'center',
        borderRadius: 2,
        marginBottom: 0,
    },
    heading: {
        color: Colors.secondaryColor,
        margin: theme.spacing(2)
    },
    inputContainer: {
        width: '100%',
        maxWidth: 540,
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    input: {
        width: 222,
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    textareaInput: {
        width: '100%',
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3)
    },
    helperText: {
        color: 'lightgray',
        marginLeft: theme.spacing(2)
    },
    uploadPictureContainer: {
        justifyContent: 'center',
        margin: theme.spacing(1)
    },
    displayPicture: {
        height: '25vmin',
        width: '35vmin',
        borderRadius: 10,
        objectFit: 'contain',
        backgroundColor: Colors.black,
        margin: 5,
    },
    fileInput: {
        width: 222,
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(1 * 0.5)
    },
    button: {
        width: 150,
        margin: theme.spacing(2),
    },
    cancelButton: {
        width: 150,
        margin: theme.spacing(2),
        color: Colors.grey4,
        border: 0
    },
}));