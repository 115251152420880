import React, { useState } from 'react';

import { Button, makeStyles, SvgIcon, TextField } from '@material-ui/core';

import { ReactComponent as VisaIcon } from 'img/bxl-visa.svg';
import { ReactComponent as MasterCardIcon } from 'img/master-card.svg';

import Colors from 'Colors';
import CardModel from 'models/CardModel';

interface Props {
    handleCardDetails: (card: CardModel) => void
    handleDisplayCardNumber: (cardNumber: string) => void
    handleDisplayExpiryMonth: (expiryMonth: string) => void
    handleDisplayExpiryYear: (expiryYear: string) => void
}
export default function EnterCardDetails({ handleCardDetails, handleDisplayCardNumber, handleDisplayExpiryMonth, handleDisplayExpiryYear }: Props) {
    const classes = useStyles();

    const [cardNumberInput, setCardNumber] = useState("");
    const [cardExpiryMonth, setCardExpiryMonth] = useState("");
    const [cardExpiryYear, setCardExpiryYear] = useState("");
    const [cvv, setCvv] = useState("");
    const [errorMessages, setErrorMessage] = useState("");

    const cardExpiryYearRef = React.useRef<HTMLInputElement>(null);

    const onCardNumberChange = (text: string) => {
        setCardNumber(text);
        handleDisplayCardNumber(text);
    }

    const expiryMonthChange = (text: string) => {
        if (parseFloat(text) > 12) return;
        setCardExpiryMonth(text);
        handleDisplayExpiryMonth(text);

        if (text.length == 2 && cardExpiryYearRef.current !== null) {
            cardExpiryYearRef.current.focus();
        }
    }

    const expiryYearChange = (text: string) => {
        setCardExpiryYear(text);
        handleDisplayExpiryYear(text);
    }

    const onSubmit = (e: any) => {
        e.preventDefault();

        if (!cardNumberInput) {
            setErrorMessage('Please specify the card number');
            return;
        }
        else if (!cardExpiryMonth) {
            setErrorMessage('Please specify the card expiry month');
            return;
        }
        else if (!cardExpiryYear) {
            setErrorMessage('Please specify the card expiry year');
            return;
        }
        else if (!cvv) {
            setErrorMessage('Please specify the 3 digits CVV Number (located behind your card)');
            return;
        }
        else if (cardNumberInput.length < 10) {
            setErrorMessage('Invalid card number');
            return;
        }

        let expiryDate = (cardExpiryMonth.toString().length < 2 ? "0" : "") + cardExpiryMonth +
            "20" + (cardExpiryYear.toString().length < 2 ? "0" : "") + cardExpiryYear;
        const model = new CardModel(cardNumberInput, expiryDate, cvv.toString());

        handleCardDetails(model);
    }

    return (
        <form onSubmit={onSubmit} className={classes.container}>
            <h3 style={{ color: Colors.grey4 }}>Payment Card</h3>
            <div className={classes.errorMessage}>{errorMessages}</div>
            <TextField
                label='Card Number'
                variant="outlined"
                margin="dense"
                autoComplete="off"
                className={classes.detailsInput}
                onChange={(e) => onCardNumberChange(e.target.value.trim())}
                value={cardNumberInput}
            />
            <div className={classes.caption}>Expiry Date</div>
            <div className={classes.cardDate}>
                <TextField
                    label='Month'
                    variant="outlined"
                    margin="dense"
                    type="number"
                    autoComplete="off"
                    className={classes.detailsInput} style={{ width: 75 }}
                    placeholder='MM'
                    onChange={(e) => expiryMonthChange(e.target.value.trim())}
                    value={cardExpiryMonth}
                />
                <div style={{ color: Colors.grey4, fontSize: 30 }}>/</div>
                <TextField ref={cardExpiryYearRef}
                    label='Year'
                    variant="outlined"
                    margin="dense"
                    type="number"
                    autoComplete="off"
                    className={classes.detailsInput} style={{ width: 75 }}
                    placeholder='YY'
                    onChange={(e) => expiryYearChange(e.target.value.trim())}
                    value={cardExpiryYear}
                />
            </div>
            <TextField
                label='CVV'
                variant="outlined"
                margin="dense"
                className={classes.detailsInput} style={{ width: 100 }}
                type="password"
                autoComplete="new-password"
                onChange={(e) => setCvv(e.target.value.trim())}
                value={cvv}
            />
            <Button
                className={classes.button}
                variant="outlined"
                type="submit">
                Continue
            </Button>
            {/* <TouchableOpacity
                style={[MainStyles.button2, {alignSelf: 'center'}]}
                disabled={this.state.processing}
                onPress={this.props.closeModal}>
                    <Ionicons
                        name="md-close"
                        color={Colors.primaryColor}
                        size={32} />
                    <Text style={MainStyles.buttonCaption2}>Cancel</Text>
            </TouchableOpacity>  */}
        </form>
    )
}

interface DisplayProps {
    cardNumber: string,
    cardExpiryMonth: string,
    cardExpiryYear: string
}
export function DisplayCardInfo({ cardNumber, cardExpiryMonth, cardExpiryYear }: DisplayProps) {
    const classes = useStyles();

    var value = cardNumber.replace(/\s+/g, '').replace(/[^0-9]/gi, '')
    var matches = value.match(/\d{4,16}/g);
    var match = matches && matches[0] || '';
    var parts = [];

    for (let i = 0, len = match.length; i < len; i += 4) {
        parts.push(match.substring(i, i + 4))
    }

    let provider = (cardNumber.length > 0 && cardNumber.charAt(0) === '4')
        ? <VisaIcon />
        : <MasterCardIcon />;

    const cardNumberDisplay = (parts.length) ? parts.join(' ') : cardNumber;

    return (
        <div className={classes.cardDisplay}>
            <div>{cardNumberDisplay}</div>
            <div style={{ width: '100%' }}>
                <div className={classes.cardDateDisplay}>{cardExpiryMonth}/{cardExpiryYear}</div>
                <div style={{ textAlign: 'right' }}>{provider}</div>
            </div>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    caption: {
        textAlign: 'center',
        color: Colors.secondaryColor,
        marginTop: 10,
        fontSize: 15
    },
    detailsInput: {
        width: 260,
        borderColor: Colors.grey4,
        borderRadius: 5,
        borderWidth: 1,
        textAlign: 'center',
        alignSelf: 'center',
        color: Colors.grey4,
        margin: 10
    },
    cardDisplay: {
        display: 'inline-flex',
        flexDirection: 'column',
        width: 150,
        height: 60,
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: Colors.grey4,
        borderRadius: 10,
        padding: 10,
        margin: 10,
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
        color: Colors.grey4,
        fontSize: 14
    },
    cardDate: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    },
    cardDateDisplay: {
        flex: 1,
        paddingTop: 5
    },
    button: {
        width: 150,
        margin: theme.spacing(2),
        alignSelf: 'center'
    },
    errorMessage: {
        color: `${Colors.lightRed} !important`,
        fontSize: '12pt !important'
    }
}))