import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import appRoutes from 'app/appRoutes';
import { RootState } from 'app/rootReducer';

import dpPlaceholder from 'img/placeholders_logo.jpg';

import { withStyles } from '@material-ui/core/styles';
import { Box, Button, CircularProgress, createStyles, Grid, makeStyles, Tab, Tabs, Theme, Typography } from '@material-ui/core';

import Colors from 'Colors';

import { fetchProductById } from './tradeApi';
import { fetchDetailedProfile } from 'features/profile/profileApi';
import ShopItem from 'features/trade/ShopItem';
import ProductsHeader from './ProductsHeader';
import HttpResponseMessages from 'features/errorHandling/HttpResponseMessages';

const GlobalCss = withStyles({
    // @global is handled by jss-plugin-global.
    '@global': {
      // You should target [class*="MuiButton-root"] instead if you nest themes.
      '.MuiButton-outlined': {
        color: Colors.ivory,
        borderRadius: 10,
        border: 'solid 2px transparent',
        background: `linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, ${Colors.secondaryColor}, ${Colors.lime})`,
        backgroundOrigin: 'border-box',
        boxShadow: `2px 1000px 1px ${Colors.grey1} inset`
      },
      '.MuiOutlinedInput-root': {  
        color: Colors.ivory, 
        backgroundColor: Colors.grey0,        
      },
      '.MuiOutlinedFormControl-root': {  
        '& fieldset': { 
            borderColor: Colors.secondaryColor,
          },
      },
      '.MuiInputLabel-root': {   
        color: Colors.ivory,  
        
      }
    },
  })(() => null);

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}
function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    const classes = useStyles();

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            className={classes.detailsTabPanel}
            {...other}
        >
        {value === index && (
            <Box p={3}>
                <Typography>{children}</Typography>
            </Box>
        )}
        </div>
    );
}

interface StyledTabsProps {
    value: number;
    onChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
  }
const StyledTabs = withStyles({
    root: {
    },
    indicator: {
        backgroundColor: Colors.ivory
        /* display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
        '& > div': {
            maxWidth: 40,
            width: '100%',
            backgroundColor: '#635ee7',
        }, */
    },
  })((props: StyledTabsProps) => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />);
   
interface StyledTabProps {
    label: string;
  }
const StyledTab = withStyles((theme: Theme) =>
    createStyles({
        root: {
            textTransform: 'none',
            minWidth: 72,
            fontWeight: theme.typography.fontWeightRegular,
            marginRight: theme.spacing(4),
            marginLeft: theme.spacing(3),
            fontFamily: [
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(','),
            '&:hover': {
                color: '#40a9ff',
                opacity: 1,
            },
            '&$selected': {
                backgroundColor: Colors.ivory,
                color: Colors.primaryColor,
                fontWeight: theme.typography.fontWeightMedium,
            },
            '&:focus': {
                color: '#40a9ff',
            },
        },
        selected: {},
    }),
)((props: StyledTabProps) => <Tab disableRipple {...props} />);
  
export default function ProductDetails(props: any){
    const history = useHistory();
    const classes = useStyles();
    const dispatch = useDispatch();
    
  const [tabValue, setTabValue] = React.useState(0);

    const { detailedProfile, profileIsLoading } = useSelector(
        (state: RootState) => state.profile
    )

    const { isMobilePhone } = useSelector(
        (state: RootState) => state.app
    )

    const { listingItem, loadTradeProcessing } = useSelector(
        (state: RootState) => state.trade
    )

    useEffect(() => {
        const itemId = props.match.params.id;
        dispatch(fetchProductById(itemId));

    }, [])

    if(listingItem && !detailedProfile && !profileIsLoading) {
        dispatch(fetchDetailedProfile(listingItem.company, "product", isMobilePhone));
    }
    
    if(!listingItem && !loadTradeProcessing) return(<HttpResponseMessages />);

    const discount = listingItem && listingItem.price * 0.17 < 50 ? listingItem.price * 0.17 : 50;
    
    return(
        <div className={classes.root}> 
            <GlobalCss />           
            <ProductsHeader />
            <HttpResponseMessages />
            {loadTradeProcessing || !listingItem ? <CircularProgress /> :
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6} style={{ textAlign: 'left', paddingLeft: 40 }}>
                        <img src={`data:image/jpeg;base64,${listingItem.thumbnail}`} className={classes.media} alt="" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {detailedProfile ? <ShopItem
                            item={listingItem.id} 
                            heading={listingItem.alias}
                            price={listingItem.price - discount}
                            companyPin={detailedProfile.profile.pin}
                            emailAddress={detailedProfile.profile.email}
                            isMobilePhone={isMobilePhone} /> : "Loading Seller Info ..."}
                    </Grid>
                    <Grid item xs={12}>                    
                        <StyledTabs value={tabValue} onChange={(event: React.ChangeEvent<{}>, newValue: number) => setTabValue(newValue)}>
                            <StyledTab label="Description" />
                        </StyledTabs>
                        <TabPanel value={tabValue} index={0}>
                            {listingItem.description}
                        </TabPanel>
                    </Grid>
                </Grid>}
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    root: {
        fontFamily: "'Spartan', sans-serif",
        paddingLeft: 40,
        paddingRight: 40
    },    
    media: {
        maxWidth: 720,
        maxHeight: 360
    },
    title: {
        color: Colors.ivory,
        textAlign: 'left',
        fontSize: 18,
        fontWeight: 'bold'
    },
    detailsTabPanel: {
        marginLeft: 24, 
        marginRight: 60, 
        textAlign: 'left', 
        border: `solid 1px ${Colors.ivory}`, 
        color: Colors.ivory
    }
}));