import ICard from "interfaces/ICard";

export default class CardModel implements ICard
{
    id!: string;
    cardNumber: string;
    expiryDate: string;
    cvvNumber: string;
    isValid: boolean;
    isDefault: boolean;

    constructor(cardNumber: string, expiryDate: string, cvvNumber: string){
        this.cardNumber = cardNumber;
        this.expiryDate = expiryDate;
        this.cvvNumber = cvvNumber;
        this.isValid = true;
        this.isDefault = true;     
    }
}