import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'app/rootReducer';

import { Button, CircularProgress, FormControl, TextField, makeStyles } from '@material-ui/core';

import Colors from 'Colors';

import CategoriesSelector from './CategoriesSelector';
import IItemCategory from 'interfaces/IItemCategory';
import CatalogueItemModel from 'models/CatalogueItemModel';

import { fetchAllItemCategories, fetchAllBusinessTypes, submitNewItem } from './tradeApi';
import { clearErrorMessage } from 'features/errorHandling/errorHandlingSlice';

interface Props {
    onCloseNewItem: React.Dispatch<React.SetStateAction<boolean>>
}
export default function NewCatalogueItem({ onCloseNewItem }: Props) {
    const classes = useStyles();

    const dispatch = useDispatch();

    const [selectedCategory, setSelectedCategory] = useState<IItemCategory | null>(null);
    const [description, setDescription] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const { accessToken } = useSelector( 
        (state: RootState) => state.user
      )

    const { loadTradeProcessing, tradeErrorMessage, itemCategories, businessTypes } = useSelector( 
        (state: RootState) => state.trade
      )
    
    useEffect(() => {
        if(!itemCategories && !loadTradeProcessing) dispatch(fetchAllItemCategories(accessToken));
        if(!businessTypes && !loadTradeProcessing) dispatch(fetchAllBusinessTypes(accessToken));
    })

    const onSelectedCategory = (item: IItemCategory) => {
        setSelectedCategory(item);
    }

    const onSubmitClick = (e: any) => {
        e.preventDefault();
        dispatch(clearErrorMessage());
        setErrorMessage("");

        if(!selectedCategory){
            setErrorMessage("Please select the Category");
            return;
        }
        if(!description){
            setErrorMessage("Name/Title not specified.");
            return;
        }

        const model = new CatalogueItemModel(description.trim(), selectedCategory);
        
        dispatch(submitNewItem(accessToken, model));
    }

    return(
        <form onSubmit={onSubmitClick} className={classes.container}>
           {itemCategories && businessTypes ? <CategoriesSelector
                categories={itemCategories}
                businessTypes={businessTypes}
                onSelectedCategory={onSelectedCategory}                 
            /> : <CircularProgress />}
            <FormControl className={classes.inputContainer}>
                <TextField
                    label='Description'
                    variant="outlined"
                    color="primary"
                    className={classes.textareaInput}
                    onChange={(e) => setDescription(e.target.value)}
                    value={description}
                />
                {/* <FormHelperText className={classes.helperText}>What is the item generally known as</FormHelperText> */}
            </FormControl>
            {!loadTradeProcessing ? <Button
                className={classes.button}
                type="submit"
                variant="outlined">
                Continue
            </Button>
            : <CircularProgress />}   
            <Button
                disabled={loadTradeProcessing} 
                style={{ color: Colors.grey4, fontSize: 18 }} onClick={() => 
                onCloseNewItem(false)}>
                X
            </Button>            
            <p style={{ color: Colors.orangeRed }}>{errorMessage}</p>   
            {/* 
            {listingId ? 
                <Typography color="primary">Listing Submitted Successfully</Typography> 
                : null 
            }   */}                  
        </form>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: "row",
        flexWrap: 'wrap',
        width: '100%',
        color: Colors.grey5,
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        justifyItems: 'center',
        borderRadius: 2,
        marginBottom: theme.spacing(2),
        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
        borderBottomColor: Colors.ivory
    },
    inputContainer: {
      width: '100%',
      maxWidth: 540, 
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center', 
      marginTop: theme.spacing(1),   
      marginRight: theme.spacing(4),
      marginBottom: theme.spacing(2),
    },
    textareaInput: {
        width: '100%',
        height: 50,
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
    },
    helperText: {
        color: 'lightgray', 
        marginLeft: theme.spacing(2)
    },
    button: {
        width: 150,
        height: 50,
        margin: theme.spacing(2),
    },
}));