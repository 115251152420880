import React from 'react';
import NumberFormat from 'react-number-format';

interface AmountInputFormatProps {
    inputRef: (instance: NumberFormat | null) => void;
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
  }
  
export const AmountInputFormat: React.FC<AmountInputFormatProps> = (props) => {
    const { inputRef, onChange, ...other } = props;
  
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator={" "}
        isNumericString
        prefix="R "
      />
    );
  }
  
interface AmountDisplayFormatProps {
  displayCurrency?: boolean,
  decimalScale?: number
}
export const AmountDisplayFormat = (props: React.PropsWithChildren<AmountDisplayFormatProps>) => {
  const value = props.children?.toString();
  
  return (
    <NumberFormat
      value={value} 
      displayType={'text'} 
      thousandSeparator={" "}
      decimalScale={props.decimalScale}
      isNumericString
      prefix={props.displayCurrency ? "R " : ""}
      renderText={value => <span>{value}</span>}
    />
  );
}

AmountDisplayFormat.defaultProps = {
  text: 0,
  displayCurrency: false,
  decimalScale: 0
};