import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';

import KbLogo from 'img/logo_secured_white.png';

import Colors from 'Colors';

const GlobalCss = withStyles({
  // @global is handled by jss-plugin-global.
  '@global': {
    // You should target [class*="MuiButton-root"] instead if you nest themes.
    '.MuiButton-outlined': {
      color: Colors.grey4,
      borderRadius: 10,
      border: 'solid 2px transparent',
      background: `linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, ${Colors.lightGreen1}, ${Colors.lime})`,
      backgroundOrigin: 'border-box',
      boxShadow: `2px 1000px 1px ${Colors.grey1} inset`
    },
    '.MuiOutlinedInput-root': {  
      color: Colors.grey4, 
      fontSize: 14,
        '& fieldset': { 
          borderColor: Colors.grey4,
        },
        '&:hover fieldset': {
          borderColor: `${Colors.yellowgreen} !important`,
        },
        '&.Mui-focused fieldset': {
          borderColor: `${Colors.primaryColor} !important`,
          color: `${Colors.grey4} !important`
        }
    },
    '.MuiOutlinedFormControl-root': {  
      '& fieldset': { 
          borderColor: Colors.grey4,
        },
    },
    '.MuiInputLabel-root': {   
      color: Colors.grey4,  
      '&.Mui-focused': {
        color: `${Colors.grey4} !important`
      }  
    }
  },
})(() => null);

interface Props {
  isMobilePhone: boolean
}

export default function PayMainView(props: React.PropsWithChildren<Props>) {
  const classes = useStyles();
    return (
      <div className="App-body">
        <GlobalCss />
        <div className={props.isMobilePhone ? classes.containerMobile : classes.container}>
              {props.children}    
        </div>
            
        {!props.isMobilePhone && <img src={KbLogo} style={{ width: 125, marginTop: 40 }} alt="KhonaBiz" />} 
      </div>
    );
}

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 860,
    width: '85%', 
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(1),
    borderColor: Colors.ivory,
    borderStyle: 'solid',
    borderWidth: 1
  },
  containerMobile: {
    width: '100%',
    minHeight: '100vh',
    maxWidth: 500,    
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(2),
  },
}));