import ICompanyProfile from "interfaces/ICompanyProfile";
import IDetailedProfile from "interfaces/IDetailedProfile";

export default class DetailedProfileModel implements IDetailedProfile {
    id: string;
    profile!: ICompanyProfile;
    friendlyUserName: string;
    catalogueCategoriesHeading!: string;
    description: string;
    motto: string;
    tagLine!: string;
    primaryThemeColor: string;
    actionButtonText!: string;
    rating!: number;
    visitId!: string;

    constructor(companyProfileId: string, friendlyUserName: string, description: string, motto: string, primaryThemeColor: string)
    {
        this.id = companyProfileId;
        this.friendlyUserName = friendlyUserName;
        this.description = description;
        this.motto = motto;
        this.primaryThemeColor = primaryThemeColor;
    }
}