import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import ICatalogueItem from 'interfaces/ICatalogueItem';
import IItemCategory from 'interfaces/IItemCategory';
import IBusinessType from 'interfaces/IBusinessType';
import IListing from 'interfaces/IListing';
import IAcquisition from 'interfaces/IAcquisition';

interface ITradeState {
    loadTradeProcessing: boolean,
    tradeErrorMessage: string,
    catalogueItems: Array<ICatalogueItem> | null,
    itemCategories: Array<IItemCategory> | null,
    businessTypes: Array<IBusinessType> | null,
    catalogueItem: ICatalogueItem | null,
    listingId: string,
    listingPictureUploaded: boolean,
    listingItems: Array<IListing> | null,
    listingItem: IListing | null,
    acquisitionProcessing: boolean,
    acquisitionErrorMessage: string,
    acquisition: IAcquisition | null,
    enquiryProcessing: boolean,
    enquiryErrorMessage: string,
    enquirySubmittedSuccessfully: boolean
}

let initialState : ITradeState = {
    loadTradeProcessing: false,
    tradeErrorMessage: "",
    catalogueItems: null,
    itemCategories: null,
    businessTypes: null,
    catalogueItem: null,
    listingId: "",
    listingPictureUploaded: false,
    listingItems: null,
    listingItem: null,
    acquisitionProcessing: false,
    acquisitionErrorMessage: "",
    acquisition: null,
    enquiryProcessing: false,
    enquiryErrorMessage: "",
    enquirySubmittedSuccessfully: false
}

const tradeSlice = createSlice({
    name: 'trade',
    initialState,
    reducers: {
        tradeProcessingStart(state) {
            state.loadTradeProcessing = true;
            state.tradeErrorMessage = "";
        },
        setTradeError(state, { payload }: PayloadAction<string>) {
            state.loadTradeProcessing = false;
            state.tradeErrorMessage = payload;
        },
        setCatalogueItems(state, { payload }: PayloadAction<Array<ICatalogueItem>>) {
            state.loadTradeProcessing = false;
            state.catalogueItems = payload;
            state.tradeErrorMessage = "";
        },
        setItemCategories(state, { payload }: PayloadAction<Array<IItemCategory>>) {
            state.loadTradeProcessing = false;
            state.itemCategories = payload;
            state.tradeErrorMessage = "";
        },
        setBusinessTypes(state, { payload }: PayloadAction<Array<IBusinessType>>) {
            state.loadTradeProcessing = false;
            state.businessTypes = payload;
            state.tradeErrorMessage = "";
        },
        setListingSavedSuccessfully(state, { payload }: PayloadAction<string>) {
            state.loadTradeProcessing = false;
            state.listingId = payload;
        },
        setListingItem(state, { payload }: PayloadAction<IListing>) {
            state.loadTradeProcessing = false;
            state.listingItem = payload;
        },
        loadListingItemStart(state) {
            state.loadTradeProcessing = true;
            state.tradeErrorMessage = "";
            state.listingItem = null;
        },
        setListingPictureUploaded(state) {
            state.loadTradeProcessing = false;
            state.listingPictureUploaded = true;
        },
        setListingItems(state, { payload }: PayloadAction<Array<IListing>>) {
            state.loadTradeProcessing = false;
            state.listingItems = payload;
        },
        setCatalogueItem(state, { payload }: PayloadAction<ICatalogueItem | null>){
            state.loadTradeProcessing = false;
            state.catalogueItem = payload;
            state.listingId = "";
            state.listingPictureUploaded = false;
        },
        processAcquisitionStart(state) {
            state.acquisitionProcessing = true;
            state.acquisitionErrorMessage = "";
        },
        setAcquisition(state, { payload }: PayloadAction<IAcquisition | null>) {
            state.acquisitionProcessing = false;
            state.acquisitionErrorMessage = "";
            state.acquisition = payload;
        },
        processAcquisitionError(state, { payload }: PayloadAction<string>) {
            state.acquisitionProcessing = false;
            state.acquisitionErrorMessage = payload;
        },
        processEnquiryStart(state) {
            state.enquiryProcessing = true;
            state.enquiryErrorMessage = "";
        },
        processEnquirySuccess(state) {
            state.acquisitionProcessing = false;
            state.enquiryProcessing = false;
            state.enquiryErrorMessage = "";
            state.enquirySubmittedSuccessfully = true;
        },
        processEnquiryError(state, { payload }: PayloadAction<string>) {
            state.enquiryProcessing = false;
            state.enquiryErrorMessage = payload;
            state.enquirySubmittedSuccessfully = false;
        }
    }
});

export const {
    tradeProcessingStart,
    setTradeError,
    setCatalogueItems,
    setItemCategories,
    setBusinessTypes,
    setCatalogueItem,
    setListingSavedSuccessfully,
    setListingPictureUploaded,
    setListingItems,
    setListingItem,
    loadListingItemStart,
    processAcquisitionStart,
    setAcquisition,
    processAcquisitionError,
    processEnquiryStart,
    processEnquirySuccess,
    processEnquiryError
} = tradeSlice.actions;

export default tradeSlice.reducer;