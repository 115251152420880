import IListing from "interfaces/IListing";

export default class ListingModel implements IListing{
    id!: string;
    company: string;
    catalogueItem: string;
    type: number;
    alias: string;
    description: string;
    model: string;
    yearModel!: string;
    expiryDate!: Date;
    price: number;
    deposit!: number;
    isDepositRequired: boolean;
    discountCode: string;
    thumbnail!: string;
    discount!: number;
    inStock: boolean;
    deliveryDays!: number;
    dateListed: Date;
    dateModified: Date;
    createdBy: string;
    modifiedBy: string;
    isActive: boolean;
    
    constructor(companyPin: string, type: number, catalogueItem: string, alias: string, description: string, price: number){
        //this.id = id;
        this.company = companyPin;
        this.type = type;
        this.alias = alias;
        this.catalogueItem = catalogueItem;
        this.description = description
        this.model = '';
        //this.yearModel = null;
        //this.expiryDate = null;
        this.price = price;
        this.isDepositRequired = false;
        this.discountCode = '';
        //this.discount = null;
        //this.Thumbnail = thumbnail;
        this.inStock = true;
        //this.deliveryDays = null;
        this.dateListed = new Date();
        this.dateModified = new Date();
        this.createdBy = '';
        this.modifiedBy = '';
        this.isActive = true;
      }
}