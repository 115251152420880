const colors = {
    primaryColor: '#036552',
    secondaryColor: '#6C943A', //'#f6ffeb'
    lightGreen: '#f0f7e6', //lighter
    lightGreen1: '#5fa1a1',
    lime: 'lime',
    yellowgreen: 'yellowgreen',
    lightRed: '#ff6262',
    brightRed: '#dc143c',
    maroon: 'maroon',
    orangeRed: 'orangered',
    orange1: '#c35000',
    orange2: '#ef9e13', //lighter
    navy: '#24414b',
    blue: 'rgb(65, 132, 244)',
    lightBlue: 'lightblue',
    lightBlue1: '#d7f4eb', //lighter
    grey0: '#181818',
    grey1: '#333333',
    grey2: '#777777',
    grey3: 'grey',
    grey4: '#a1a1a1',
    grey5: 'lightgrey', //lighter
    wheat: 'wheat',
    ivory: '#fffff0',
    ivory1: '#fffff8', //lighter
    ivory2: '#ffffe7',
    white: 'white',
    semiBlack: '#080808', 
    black: 'black',
    transparent: 'transparent'     
}

export default colors;