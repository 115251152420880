import { AppThunk } from 'app/store';
import { setErrorMessage } from 'features/errorHandling/errorHandlingSlice';
import {
    loadProfileStart, loadOtherInfoStart,
    profileFound, profileBannerFound,
    loadCatalogueCategoriesStart, catalogueCategoriesFound, loadCatalogueCategoriesError,
    profileLinksFound, setVisitId, loadProfileError
} from './profileSlice';

export const fetchDomainProfile = (domain: string, source: string, isMobilePhone: boolean): AppThunk => async dispatch => {
    try {
        dispatch(loadProfileStart());
        const url = `${process.env.REACT_APP_ACCOUNT_URL}/api/PublicProfile/ProfileByDomain?domain=${domain}&source=${source}&isMobilePhone=${isMobilePhone}`;
        await fetch(url).then(response => {
            if (response.status === 200) {
                response.json().then(results => {
                    dispatch(profileFound(results));
                })
            }
            else {
                dispatch(loadProfileError("Profile Not Found"));
                dispatch(setErrorMessage("Profile Not Found"));
            }
        })
            .catch((error) => {
                dispatch(loadProfileError(error.message));
                dispatch(setErrorMessage(error.message));
            });
    }
    catch (error) {
        dispatch(loadProfileError(error.message));
        dispatch(setErrorMessage(error.message));
    }
}

export const fetchDetailedProfile = (company: string, source: string, isMobilePhone: boolean): AppThunk => async dispatch => {
    try {
        dispatch(loadProfileStart());
        const url = `${process.env.REACT_APP_ACCOUNT_URL}/api/PublicProfile/DetailedProfile?company=${company}&source=${source}&isMobilePhone=${isMobilePhone}`;
        await fetch(url).then(response => {
            if (response.status === 200) {
                response.json().then(results => {
                    dispatch(profileFound(results));
                })
            }
            else {
                dispatch(loadProfileError("Profile Not Found"));
                dispatch(setErrorMessage("Profile Not Found"));
            }
        })
            .catch((error) => {
                dispatch(loadProfileError(error.message));
                dispatch(setErrorMessage(error.message));
            });
    }
    catch (error) {
        dispatch(loadProfileError(error.message));
        dispatch(setErrorMessage(error.message));
    }
}

export const submitVisit = (company: string, source: string, isMobilePhone: boolean): AppThunk => async dispatch => {
    try {
        dispatch(loadOtherInfoStart());
        const url = `${process.env.REACT_APP_ACCOUNT_URL}/api/PublicProfile/Visit?company=${company}&source=${source}&isMobilePhone=${isMobilePhone}`;
        await fetch(url).then(response => {
            if (response.status === 200) {
                response.json().then(results => {
                    dispatch(setVisitId(results));
                })
            }
            else {
                dispatch(loadProfileError("Could not record request"));
                dispatch(setErrorMessage("Could not record request"));
            }
        })
            .catch((error) => {
                dispatch(loadProfileError(error.message));
                dispatch(setErrorMessage(error.message));
            });
    }
    catch (error) {
        dispatch(loadProfileError(error.message));
        dispatch(setErrorMessage(error.message));
    }
}

export const fetchProfileBanner = (company: string): AppThunk => async dispatch => {
    try {
        dispatch(loadOtherInfoStart());
        const url = `${process.env.REACT_APP_ACCOUNT_URL}/api/PublicProfile/Banner?company=${company}`;
        await fetch(url).then(response => {
            if (response.status === 200) {
                response.json().then(results => {
                    dispatch(profileBannerFound(results));
                })
            }
            else {
                dispatch(loadProfileError("Could not load Profile Banner"));
                dispatch(setErrorMessage("Could not load Profile Banner"));
            }
        })
        .catch((error) => {
            dispatch(loadProfileError(error.message));
            dispatch(setErrorMessage(error.message));
        });
    }
    catch (error) {
        dispatch(loadProfileError(error.message));
        dispatch(setErrorMessage(error.message));
    }
}

export const fetchCatalogueCategories = (company: string): AppThunk => async dispatch => {
    try {
        dispatch(loadCatalogueCategoriesStart());
        const url = `${process.env.REACT_APP_TRADE_URL}/api/Listing/CatalogueCategories?pin=${company}`;
        await fetch(url).then(response => {
            if (response.status === 200) {
                response.json().then(results => {
                    dispatch(catalogueCategoriesFound(results));
                })
            }
            else {
                dispatch(loadProfileError("Could not load Product/Services"));
                dispatch(setErrorMessage("Could not load Product/Services"));
            }
        })
            .catch((error) => {
                dispatch(loadProfileError(error.message));
                dispatch(setErrorMessage(error.message));
            });
    }
    catch (error) {
        dispatch(loadProfileError(error.message));
        dispatch(setErrorMessage(error.message));
    }
}

export const fetchProfileLinks = (company: string): AppThunk => async dispatch => {
    try {
        dispatch(loadOtherInfoStart());
        const url = `${process.env.REACT_APP_ACCOUNT_URL}/api/PublicProfile/Links?company=${company}`;
        const results = await fetch(url).then(response => {
            if (response.status === 200) {
                response.json().then(results => {
                    dispatch(profileLinksFound(results));
                })
            }
            else {
                dispatch(loadProfileError("Could not load Profile Links"));
                dispatch(setErrorMessage("Could not load Profile Links"));
            }
        })
            .catch((error) => {
                dispatch(loadProfileError(error.message));
                dispatch(setErrorMessage(error.message));
            });
    }
    catch (error) {
        dispatch(loadProfileError(error.message));
        dispatch(setErrorMessage(error.message));
    }
}