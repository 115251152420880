import IErrorMessage from "interfaces/IErrorMessage";

export default class ErrorMessageModel implements IErrorMessage{
    message: string;
    code: number;
    action: string;
    
    constructor(code: number, action: string = "", message: string = ""){
        this.message = message;
        this.code = code;
        this.action = action;
    }
}