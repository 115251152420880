import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IAppState {
    isMobilePhone: boolean,
    popupMessage: string,
    showPopupMessage: boolean,
}

let initialState : IAppState = {
    isMobilePhone: false,
    popupMessage: "",
    showPopupMessage: false,
}

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setIsMobilePhone(state, { payload } : PayloadAction<boolean>){
            state.isMobilePhone = payload;
        },
        openPopUpMessage(state, { payload } : PayloadAction<string>) {
            state.popupMessage = payload;
            state.showPopupMessage = true;
        },
        closePopupMessage(state){
            state.popupMessage = "";
            state.showPopupMessage = false;
        }
    }
});

export const {
    setIsMobilePhone,
    openPopUpMessage,
    closePopupMessage,
} = appSlice.actions

export default appSlice.reducer;