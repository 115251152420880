import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'app/rootReducer';
import appRoutes from 'app/appRoutes';

import { makeStyles, Snackbar } from '@material-ui/core';
import Colors from 'Colors';
import { useHistory } from 'react-router';
import { Alert } from '@material-ui/lab';
import { clearErrorMessage } from './errorHandlingSlice';

interface Props {
    showInPopup?: boolean
}
export default function HttpResponseMessages({ showInPopup }: Props) {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();

    const [message, setMessage] = useState("");
    const [showPopupMessage, setShowPopupMessage] = useState(false);

    const { errorMessage, httpAction, errorCode } = useSelector(
        (state: RootState) => state.error
    )

    const closePopUp = () => {
        setShowPopupMessage(false);
        dispatch(clearErrorMessage());
    }

    useEffect(() => {
        setShowPopupMessage(true);
        if (errorCode === -1 && !errorMessage) {
            setMessage("");
            setShowPopupMessage(false)
        }
        else if (errorCode === 204) {
            if (httpAction && !errorMessage) {
                setMessage(`${httpAction} might not be completed, please verify with the admin`);
            }
            else if (errorMessage) {
                setMessage(errorMessage);
            }
        }
        else if (errorCode === 400) { console.log(httpAction, errorCode, errorMessage);
            if (httpAction && !errorMessage) {
                setMessage(`Application Error in ${httpAction}, please try again or contact the admin`);
            }
            else if (errorMessage) {
                setMessage(errorMessage);
            }
            else {
                setMessage(`Unkown error, please try again or contact the admin`);
            }
        }
        else if (errorCode === 401) {
            setMessage(`User not authenticated or token expired`);
            history.push(appRoutes.signOut);
        }
        else if (errorCode === 403) {
            setMessage(`Server responded with "Forbidden" error, please contact the admin`);
        }
        else if (errorCode === 404) {
            setMessage(`Server responded with "Not Found" error, please contact the admin`);
        }
        else if (errorCode === 500) {
            setMessage("Server error, please try again or contact the admin");
        }
        else {
            if (httpAction && !errorMessage) {
                setMessage(`Unknown Problem in ${httpAction}, please try again or contact the admin`);
            }
            else if (errorMessage) {
                setMessage(errorMessage);
            }
            else {
                //setMessage("Unknown Problem, please try again or contact the admin"); 
            }
        }

    })

    if (showInPopup) {
        return (
            <Snackbar open={showPopupMessage && message !== ""} autoHideDuration={5000} onClose={() => closePopUp()}>
                <Alert onClose={() => closePopUp()} severity="error">
                    {message}
                </Alert>
            </Snackbar>
        )
    }
    else {
        return (
            <div className={classes.errorMessage}>
                {message}
            </div>
        )
    }
}

const useStyles = makeStyles((theme) => ({
    errorMessage: {
        color: `${Colors.lightRed} !important`,
        fontSize: '12pt !important',
        margin: theme.spacing(1)
    }
}))