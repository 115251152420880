import ICard from "interfaces/ICard";
import IPayment from "interfaces/IPayment";

export default class PaymentModel implements IPayment
{
    accessToken: string;
    description: string;
    card: ICard;
    saveCard: boolean;
    amount: number;
    companyPin: string;
    paymentType: number;
    acquisitionReference: string;

    constructor(accessToken: string, description: string, card: ICard, amount: number, companyPin: string, paymentType: number, acquisitionReference: string){
        this.accessToken = accessToken;
        this.description = description;
        this.card = card;
        this.saveCard = false;
        this.amount = amount;
        this.companyPin = companyPin;
        this.paymentType = paymentType; 
        this.acquisitionReference = acquisitionReference;     
    }
}
