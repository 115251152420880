import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'app/rootReducer';
import appRoutes from 'app/appRoutes';
import { useHistory } from 'react-router';

import logo from '../../img/logo3.png';
import dpPlaceholder from 'img/placeholders_logo.jpg';
import bannerPlaceholder from 'img/placeholders_banner.jpg';

import { withStyles } from '@material-ui/core/styles';
import { Typography, CircularProgress, IconButton, Button, ButtonGroup, ButtonBase, Link } from '@material-ui/core';
import { Instagram, Facebook, YouTube, Pinterest, LinkedIn, Twitter, Language, Phone, Email } from '@material-ui/icons';
import { Star, StarOutline, StarHalf } from '@material-ui/icons';

import Colors from 'Colors';

import Enquiry from 'features/trade/Enquiry';
import Acquisition from 'features/trade/Acquisition';
import { fetchDomainProfile, fetchDetailedProfile, fetchProfileBanner, fetchCatalogueCategories, fetchProfileLinks, submitVisit } from './profileApi';

import ProfileMobile from './ProfileMobile';
import { setLoadOtherInfoComplete, clearProfile } from './profileSlice';
import HttpResponseMessages from 'features/errorHandling/HttpResponseMessages';
import { clearErrorMessage } from 'features/errorHandling/errorHandlingSlice';

const GlobalCss = withStyles({
    // @global is handled by jss-plugin-global.
    '@global': {
        // You should target [class*="MuiButton-root"] instead if you nest themes.
        '.MuiButton-outlined': {
            color: Colors.ivory,
            borderRadius: 10,
            border: 'solid 2px transparent',
            background: `linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, ${Colors.secondaryColor}, ${Colors.lime})`,
            backgroundOrigin: 'border-box',
            boxShadow: `2px 1000px 1px ${Colors.grey1} inset`
        },
        '.MuiOutlinedInput-root': {
            color: Colors.ivory,
            '& fieldset': {
                borderColor: Colors.secondaryColor,
            },
        },
        '.MuiOutlinedFormControl-root': {
            '& fieldset': {
                borderColor: Colors.secondaryColor,
            },
        },
        '.MuiInputLabel-root': {
            color: Colors.ivory,
        }
    },
})(() => null);

interface Props {
    isMobilePhone: boolean
}

export default function Profile({ isMobilePhone }: Props) {
    if (!isMobilePhone) require('./Profile.css');
    const history = useHistory();
    const dispatch = useDispatch();

    const { detailedProfile, profileBanner, catalogueCategories, isLoadingCatalogueCategories, profileLinks, profileIsLoading, isLoadingOtherInfo, visitId, profileErrorMessage } = useSelector(
        (state: RootState) => state.profile
    )

    const [makePayment, setMakePayment] = useState(false);
    const [loadProfileRequested, setLoadProfileRequested] = useState(false);
    const [getByDomain] = useState(window.location.hostname !== "localhost" && 
                                        window.location.hostname.indexOf("khonabiz.com") === -1 && 
                                        window.location.hostname.indexOf("khona.biz") === -1);

    useEffect(() => {
        const params = window.location.search.substr(1).split('&');
        const profileIdentifier = params[0] && params[0].indexOf('@') !== -1 ? params[0] : "";
        const linkSourcePlatform = params[1] ? params[1].split('=')[1] : "";
        
        if (!detailedProfile && !profileErrorMessage && (getByDomain || profileIdentifier)) {
            if (!loadProfileRequested && !profileIsLoading) {
                dispatch(clearErrorMessage());
                setLoadProfileRequested(true);
                dispatch(clearProfile());
                if(getByDomain){
                    const domain = window.location.host.replace('www.','');console.log(domain);                    
                    dispatch(fetchDomainProfile(domain, linkSourcePlatform ? linkSourcePlatform : "domain", isMobilePhone));
                }
                else if(profileIdentifier) {
                    dispatch(fetchDetailedProfile(profileIdentifier, linkSourcePlatform ? linkSourcePlatform : "unknown", isMobilePhone));
                }
            }

            if (!profileBanner && !isLoadingOtherInfo && !getByDomain) dispatch(fetchProfileBanner(profileIdentifier));
            if (!profileLinks && !isLoadingOtherInfo && !getByDomain) dispatch(fetchProfileLinks(profileIdentifier));
        }
        else if (!profileIdentifier && !detailedProfile && !getByDomain) {
            if(window.location.hostname.indexOf("localhost") === -1) 
                window.location.replace('https://web.khonabiz.com?@KhonaBiz') 
            // history.push(appRoutes.search);
        }
        else if (!profileIdentifier && detailedProfile && !isLoadingOtherInfo) {
            if (!profileBanner) dispatch(fetchProfileBanner(detailedProfile.friendlyUserName));
            if (!profileLinks) dispatch(fetchProfileLinks(detailedProfile.friendlyUserName));
            if (!visitId) dispatch(submitVisit(detailedProfile.friendlyUserName, "KbSearch", isMobilePhone));
        }
        else if (profileBanner && profileLinks && visitId && isLoadingOtherInfo) {
            setLoadOtherInfoComplete();
        }
        
        if (detailedProfile && !catalogueCategories && !isLoadingCatalogueCategories) {
            dispatch(fetchCatalogueCategories(detailedProfile?.profile.pin));
        }
    })

    const goToHome = () => {
        dispatch(clearProfile());
        dispatch(clearErrorMessage());
        history.replace(appRoutes.search)
    }

    const profileLinksIcons: Record<string, any> = {
        "Instagram": <Instagram />,
        "Facebook": <Facebook />,
        "YouTube": <YouTube />,
        "Pinterest": <Pinterest />,
        "LinkedIn": <LinkedIn />,
        "Twitter": <Twitter />,
        "Web": <Language />
    }

    const ratingStars: Array<any> | null = new Array<any>();
    if (detailedProfile?.rating && ratingStars.length === 0) {
        let numberOfStars = detailedProfile.rating / 2;

        for (let i = 0; i < parseInt(numberOfStars.toString()); i++) {
            ratingStars.push(<Star key={i} color="primary" />);
        }

        if (numberOfStars.toString().indexOf('.5') !== -1) {
            ratingStars.push(<StarHalf key={ratingStars.length} color="primary" />);
        }

        if (ratingStars.length < 5) {
            for (let i = ratingStars.length; i < 5; i++) {
                ratingStars.push(<StarOutline key={i} color="primary" />);
            }
        }
    }

    if ((!detailedProfile || !profileBanner) && !profileErrorMessage) {
        return (
            <div className="App-initiate">
                <CircularProgress />
                <Typography variant="h6" gutterBottom>
                    Loading Profile ...
                </Typography>
            </div>
        )
    }
    else if (detailedProfile && profileBanner && isMobilePhone) {
        document.title = getByDomain ? detailedProfile.profile.tradingName : `KB Profile - ${detailedProfile.profile.tradingName}`;
        return (
            <ProfileMobile
                profileLinksIcons={profileLinksIcons}
                ratingStars={ratingStars}
                isMobilePhone={isMobilePhone}
                isCustomDomain={getByDomain}
                goToHome={goToHome}
            />
        )
    }
    if (profileErrorMessage) {
        return (
            <div className="App-initiate">
                <p style={{ marginBottom: 20, color: Colors.lightRed }}>Not Found</p>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => window.location.reload()}>
                    Re-try
                </Button>
                {!getByDomain && <Button
                    variant="text"
                    style={{ marginTop: 20, color: Colors.ivory, fontSize: 12 }}
                    onClick={goToHome}>
                    Go to business search
                </Button>}
            </div>
        )
    }

    if (detailedProfile?.profile) document.title = `Profile - ${detailedProfile?.profile.tradingName}`;
    
    const displayPicture = detailedProfile?.profile ? `data:image/png;base64,${detailedProfile?.profile.logo}` : dpPlaceholder;
    const banner = profileBanner && profileBanner?.length > 0 ? `data:image/png;base64,${profileBanner[0].media}` : bannerPlaceholder;

    const iconLinks = profileLinks?.filter(item => item.showAs === 0);
    const otherLinks = profileLinks?.filter(item => item.showAs !== 0);
    
    return (
        <div className="Root">
            <GlobalCss />
            <header className="Profile-header">
                <div className="Display-name">{detailedProfile?.profile.tradingName}</div>
                <img src={displayPicture} className="Display-picture" alt={detailedProfile?.profile.tradingName} />
                <div className="Profile-service">
                    {detailedProfile?.profile.primaryService ? detailedProfile?.profile.primaryService : detailedProfile?.motto}
                </div>
            </header>
            <div className="Banner">
                {detailedProfile && detailedProfile?.profile.companyType >= 10 && detailedProfile?.profile.companyType <= 25 && 
                    <Button variant="contained" color="primary" className="Action-button"
                        onClick={() => history.push(appRoutes.products)}>
                            {detailedProfile?.actionButtonText ? detailedProfile?.actionButtonText : "Shop Items"}
                    </Button>
                }
                <img src={banner} className="Banner-picture" alt="" />
            </div>
            <div className="Details-container">
                <div className="Details-left-container">
                    <div className="Details-left-inner-container">
                        <strong style={{ fontSize: 12 }}>{detailedProfile?.tagLine}</strong>
                        {otherLinks && <div className="Other-links-container">
                            {otherLinks?.map((item, index) => (
                                item.showAs === 2 ? 
                                    <Button target="_blank" variant="outlined" color="primary" href={item.link} key={index} style={{ minWidth: 265 }}>{item.name}</Button> : 
                                    <Link target="_blank" color="primary" href={item.link} key={index} style={{ minWidth: 265 }}>{item.name}</Link>
                            ))}
                        </div>}
                        {detailedProfile?.description}
                    </div>
                    <div className="Services-list">
                        {catalogueCategories && catalogueCategories.length > 0 && <p className="Sub-header">
                            {detailedProfile?.catalogueCategoriesHeading ? detailedProfile.catalogueCategoriesHeading : "Products/Services"}
                        </p>}
                        <ul>
                            {catalogueCategories?.map((item, index) => (
                                <li key={index}>&#8226; {item}</li>
                            ))}
                        </ul>
                    </div>
                    <div className="Contacts-container">
                        <div className="Platforms-container">
                            {iconLinks ? iconLinks.map((item, index) => (
                                <IconButton
                                    key={index} aria-label="upload picture"
                                    component="span"
                                    onClick={() => window.open(item.link, "_blank")}>
                                    {profileLinksIcons[item.name]}
                                </IconButton>
                            )) : null}
                        </div>
                        {detailedProfile ? <div className="Contact-info">
                            <Phone /><div>{detailedProfile.profile.contacts}</div>
                            <Email /><div>{detailedProfile.profile.email}</div>
                        </div> : null}
                    </div>
                </div>
                <div className="Details-right-container">
                    {/* <div className="Call-to-action">
                        <ButtonGroup color="primary" className="Button" aria-label="split button">
                            <Button
                                variant="contained"
                                disableElevation
                                size="large" 
                                disabled={!makePayment}
                                onClick={() => setMakePayment(false)}>
                                {detailedProfile?.actionButtonText ? detailedProfile?.actionButtonText : "Enquire"}
                            </Button>
                            <Button
                                variant="contained"
                                size="large"
                                disabled={makePayment}
                                onClick={() => setMakePayment(true)}>
                                PAY
                            </Button>
                            {detailedProfile?.primaryPlatform ? <Button
                                color="primary"
                                size="small"
                                href={detailedProfile.primaryPlatform.link}
                                target="_blank"
                            >
                                {profileLinksIcons[detailedProfile.primaryPlatform.name]}
                            </Button> : null}
                        </ButtonGroup>
                    </div> */}
                    {detailedProfile && catalogueCategories && !makePayment ?
                        <Enquiry
                            heading={detailedProfile.actionButtonText ? detailedProfile?.actionButtonText : "Enquire"}
                            visitId={visitId}
                            services={catalogueCategories}
                            isMobilePhone={isMobilePhone} /> : null}
                    {detailedProfile && makePayment ?
                        <Acquisition
                            heading={"Acquire Service / Product"}
                            companyPin={detailedProfile.profile.pin}
                            emailAddress={detailedProfile.profile.email}
                            isMobilePhone={isMobilePhone} /> : null}
                </div>
            </div>
            <div className="Footer-container">
                <div className="Footer">
                    {ratingStars && ratingStars.length > 0 && <div className="Ratings-container">
                        <div className="Ratings">
                            {ratingStars.map((item) => (
                                item
                            ))}
                        </div>
                    </div>}
                </div>
                {!getByDomain && <ButtonBase onClick={goToHome}>
                    <img src={logo} className="Logo" alt="KhonaBiz" />
                </ButtonBase>}
            </div>
        </div>
    )
}