import IBusinessType from "interfaces/IBusinessType";
import IItemCategory from "interfaces/IItemCategory";

export default class ItemCategoryModel implements IItemCategory {
    id!: number;
    description: string;
    businessType: IBusinessType | null;
    
    constructor(description: string, businessType: IBusinessType | null){
        this.description = description;
        this.businessType = businessType;
    }
}