import React, { useEffect } from 'react';

import logo from 'img/logo.png';
import './App.css';

import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from './rootReducer';
import appRoutes, { routes } from './appRoutes';

import useWindowDimensions from './WindowDimensions';

import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

import Profile from 'features/profile/Profile';
import Acquisition from 'features/trade/Acquisition';
import ProductDetails from 'features/trade/ProductDetails';

import { closePopupMessage } from './appSlice';
import { fetchUserBusiness } from 'features/account/accountApi';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#6C943A',
    },
    secondary: {
      main: '#036552',
    },
  },
});

function App() {
  const dispatch = useDispatch();

  const { showPopupMessage, popupMessage } = useSelector(
    (state: RootState) => state.app
  )

  const detailedProfile = useSelector(
    (state: RootState) => state.profile.detailedProfile
  )

  const { accessToken, isOnBoarding, userProcessing, isAuthenticated, userBusinessProfile, userProcessingError } = useSelector(
    (state: RootState) => state.user
  )

  const { isMobilePhone } = useWindowDimensions();

  /* const params = window.location.search.substr(1).split('&');
  const companyIdentify = params[0];
  const linkSourcePlatform = params[1] ? params[1].split('=')[1] : ""; */

  const themeColor = detailedProfile ? detailedProfile.primaryThemeColor : "30,30,30";

  useEffect(() => {
    if ((!isAuthenticated || !accessToken) && !userProcessing) {
    }
  }, []);


  return (
    <ThemeProvider theme={theme}>
      <div className="App" style={{ background: `radial-gradient(farthest-side ellipse at 10% 0, rgba(${themeColor},1) 20%, rgba(${themeColor},0.75))` }}>
        {/* {(isAuthenticated && !isOnBoarding && !companyIdentify) ? <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          {(!isMobilePhone && userBusinessProfile) && <div style={{ fontSize: 22 }}>{userBusinessProfile?.businessName}</div>}
          {(!isMobilePhone && userBusinessProfile) && <div>{`${userBusinessProfile?.userDisplayName} ${userBusinessProfile?.userDisplaySurname}`}</div>}         
        </header> : null} */}
        <Router>
          <Switch>
            <Route exact path={appRoutes.home}>
              <Profile
                isMobilePhone={isMobilePhone} />
            </Route>
            {routes.map((item, i) => (
              <Route key={i}
                path={item.path}>
                <item.component isMobilePhone={isMobilePhone} />
              </Route>
            ))}
            <Route path={`${appRoutes.productDetails}/:id`} component={ProductDetails} />,
            {detailedProfile && <Route path={appRoutes.acquire}>
              <Acquisition
                heading="Pay For?"
                companyPin={detailedProfile?.profile.pin}
                emailAddress={detailedProfile?.profile.email}
                isMobilePhone={isMobilePhone} />
            </Route>}
          </Switch>
        </Router>
        <Snackbar open={showPopupMessage} autoHideDuration={6000} onClose={() => dispatch(closePopupMessage())}>
          <Alert onClose={() => dispatch(closePopupMessage())} severity="success">
            {popupMessage}
          </Alert>
        </Snackbar>
      </div>
    </ThemeProvider>
  );
}

export default App;
