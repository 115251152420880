import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import IUser from 'interfaces/IUser';
import IUserBusinessProfile from 'interfaces/IUserBusinessProfile';
import IUserProfile from 'interfaces/IUserProfile';

interface IUserState {
  isOnBoarding: boolean;
  user: IUser | null;
  accessToken: string;
  isAuthenticated: boolean;
  userProfile: IUserProfile | null;
  userBusinessProfile: IUserBusinessProfile | null;
  userRegisteredSuccessfully: boolean;
  userProfileCreatedSuccessfully: boolean;
  userProcessing: boolean;
  userProcessingError: string;
}

const tokenStorage = localStorage.getItem("accessToken");
let initialState : IUserState = {
  isOnBoarding: false,
  user: null,
  accessToken: tokenStorage ? tokenStorage : "",
  isAuthenticated: tokenStorage ? true : false,
  userProfile: null,
  userBusinessProfile: null,
  userRegisteredSuccessfully: false,
  userProfileCreatedSuccessfully: false,
  userProcessing: false,
  userProcessingError: ''   
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    userProcessingStart(state) {
      state.userProcessing = true; 
      state.userProcessingError = "";
    },
    setOnBoarding(state, { payload }: PayloadAction<boolean>){
      state.isOnBoarding = payload;
      if(payload){ 
        state.accessToken = "";
        state.isAuthenticated = false;
      }
      state.userBusinessProfile = null;
    },
    setUserSigninSuccessfully(state, { payload }: PayloadAction<string>){
      localStorage.setItem("accessToken", payload);
      state.accessToken = payload;
      state.isAuthenticated = true;
      state.userProcessing = false;
      state.userProcessingError = "";
    },
    signOut(state){
      localStorage.removeItem("accessToken");
      state.accessToken = "";
      state.userBusinessProfile = null;
      state.isAuthenticated = false;
      state.userProcessing = false;
      state.userProcessingError = "";
    },
    setUserRegisteredSuccessfully(state, { payload }: PayloadAction<string>) {
      state.accessToken = payload;
      state.userRegisteredSuccessfully = true;
      state.isAuthenticated = true;
      state.userProcessing = false; 
      state.userProcessingError = "";
    },
    setUserProfileCreatedSuccessfully(state, { payload }: PayloadAction<IUserProfile>) {
      state.userProfile = payload;
      state.userProfileCreatedSuccessfully = true;
      state.userProcessing = false; 
      state.userProcessingError = "";
    },
    setUserBusinessProfile(state, { payload }: PayloadAction<IUserBusinessProfile>){
      state.userBusinessProfile = payload;
      state.userProcessing = false;
      state.userProcessingError = "";
    },
    userProcessingError(state, { payload }: PayloadAction<string>) {
      state.user = null;
      state.userRegisteredSuccessfully = false;
      state.userProcessing = false; 
      state.userProcessingError = payload;
    }
  }
});

export const {
    userProcessingStart,
    setOnBoarding,
    setUserSigninSuccessfully,
    signOut,
    setUserRegisteredSuccessfully,
    setUserProfileCreatedSuccessfully,
    setUserBusinessProfile,
    userProcessingError,
  } = userSlice.actions

export default userSlice.reducer;