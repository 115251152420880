import { AppThunk } from 'app/store';
import IUser from 'interfaces/IUser';
import IUserProfile from 'interfaces/IUserProfile';
import ICompanyProfile from 'interfaces/ICompanyProfile';
import IDetailedProfile from 'interfaces/IDetailedProfile';
import ErrorMessageModel from 'models/ErrorMessageModel';

import { userProcessingStart, setUserSigninSuccessfully, setUserRegisteredSuccessfully, setUserProfileCreatedSuccessfully, setUserBusinessProfile, userProcessingError } from './userSlice';
import {
    companyProfileProcessingStart, setDetailedProfileSubmittedSuccessfully, setCompanyProfileSumittedSuccessfully,
    companyProfileProcessingError, companyBannerUploadStart, setCompanyBannerUploadedSuccessfully
} from './companyProfileSlice';
import { setErrorCode, setErrorMessage } from 'features/errorHandling/errorHandlingSlice';

export const signIn = (email: string, password: string): AppThunk => async dispatch => {
    let password_esc = escape(password);
    try {
        dispatch(userProcessingStart());
        let payload = `client_id=khonabiz_mobileapp&client_secret=secret&username=${email}&password=${password_esc}&grant_type=password`;
        fetch(`${process.env.REACT_APP_AUTH_URL}/connect/token`, { //Todo: Please revise Api variable 
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: payload
        }).then(response => {
            if (response.status === 200) {
                response.json().then(results => {
                    dispatch(setUserSigninSuccessfully(results.access_token));
                })
            }    
            else if(response.status === 400){                 
                response.json().then((data) => {
                    if(data.error == 'invalid_grant')
                    {
                        dispatch(userProcessingError('Invalid Username or Password'));
                        dispatch(setErrorMessage('Invalid Username or Password'));
                    }
                    else
                    {
                        dispatch(userProcessingError(data.error_description));
                        dispatch(setErrorMessage(data.error_description));
                    }
                });
            } 
            else {
                dispatch(userProcessingError("Could not signin, please try again")); 
                dispatch(setErrorCode(response.status));
            }
        }).catch((error) => {
            dispatch(userProcessingError(error.message));
            dispatch(setErrorMessage(error.message));
        })
    }
    catch (error) {
        dispatch(userProcessingError(error.message));
        dispatch(setErrorMessage(error.message));
    }
}

export const registerUser = (data: IUser): AppThunk => async dispatch => {
    try {
        dispatch(userProcessingStart());
        const url = `${process.env.REACT_APP_ACCOUNT_URL}/api/User`;
        await fetch(url,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            }
        ).then(response => {
            if (response.status === 200) {
                response.json().then(results => {
                    dispatch(setUserRegisteredSuccessfully(results.access_token));
                })
            } 
            else if (response.status === 409) {
                dispatch(userProcessingError(`Username ${data.email} is already registered`));
                dispatch(setErrorMessage(`Username ${data.email} is already registered`));
            }
            else {
                dispatch(userProcessingError("Could not register user, please try again"));
                dispatch(setErrorCode(response.status));
            }
        }).catch((error) => {
            dispatch(userProcessingError(error.message));
            dispatch(setErrorMessage(error.message));
        })
    }
    catch (error) {
        dispatch(userProcessingError(error.message));
        dispatch(setErrorMessage(error.message));
    }
}

export const submitUserProfile = (token: string, data: IUserProfile): AppThunk => async dispatch => {
    try {
        dispatch(userProcessingStart());
        const url = `${process.env.REACT_APP_ACCOUNT_URL}/api/UserProfile`;
        await fetch(url,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'bearer ' + token
                },
                body: JSON.stringify(data),
            }
        ).then(response => {
            if (response.status === 200) {
                dispatch(setUserProfileCreatedSuccessfully(data));
            } 
            else {
                dispatch(userProcessingError("Could not create user profile, please try again"));
                dispatch(setErrorCode(response.status));
            }
        }).catch((error) => {
            dispatch(userProcessingError(error.message));
            dispatch(setErrorMessage(error.message));
        })
    }
    catch (error) {
        dispatch(userProcessingError(error.message));
        dispatch(setErrorMessage(error.message));
    }
}

export const submitCompanyProfile = (token: string, file: any, profile: ICompanyProfile): AppThunk => async dispatch => {
    const body = new FormData();
    body.append('displayPicture', file);
    //body.append('companyProfile', JSON.stringify(profile));
    body.append('tradingName', profile.tradingName);
    body.append('email', profile.email);
    body.append('contacts', profile.contacts);

    try {
        dispatch(companyProfileProcessingStart());
        const url = `${process.env.REACT_APP_ACCOUNT_URL}/api/CompanyProfile/WithDisplayPicture`;
        await fetch(url,
            {
                method: 'POST',
                headers: {
                    //'Content-Type': 'multipart/form-data',
                    'Authorization': 'bearer ' + token
                },
                body
            }
        ).then(response => {
            if (response.status === 200) {
                response.json().then(results => {
                    dispatch(setCompanyProfileSumittedSuccessfully(results));
                })
            }
            else {
                dispatch(companyProfileProcessingError("Could not create business profile, please try again"));
                dispatch(setErrorCode(response.status));
            }
        }).catch((error) => {
            dispatch(companyProfileProcessingError(error.message));
            dispatch(setErrorMessage(error.message));
        })
    }
    catch (error) {
        dispatch(companyProfileProcessingError(error.message));
        dispatch(setErrorMessage(error.message));
    }
}

export const submitDetailedProfile = (token: string, data: IDetailedProfile): AppThunk => async dispatch => {
    try {
        dispatch(companyProfileProcessingStart());
        const url = `${process.env.REACT_APP_ACCOUNT_URL}/api/PublicProfile`;
        await fetch(url,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'bearer ' + token
                },
                body: JSON.stringify(data),
            }
        ).then(response => {
            if (response.status === 200) {
                dispatch(setDetailedProfileSubmittedSuccessfully());
            }
            else if (response.status === 409) {
                dispatch(companyProfileProcessingError(`Username ${data.friendlyUserName} is already taken`));
                dispatch(setErrorMessage(`Username ${data.friendlyUserName} is already taken`));
            }
            else {
                dispatch(companyProfileProcessingError("Could not update profile, please try again"));
                dispatch(setErrorCode(response.status));
            }
        }).catch((error) => {
            dispatch(companyProfileProcessingError(error.message));
            dispatch(setErrorMessage(error.message));
        })
    }
    catch (error) {
        dispatch(companyProfileProcessingError(error.message));
        dispatch(setErrorMessage(error.message));
    }
}

export const uploadCompanyBanner = (token: string, file: any, companyPin: string): AppThunk => async dispatch => {
    const body = new FormData();
    body.append('mediaFile', file);
    body.append('caption', "Main Banner");
    //body.append('company', companyPin);
    //body.append('relatedService', "");

    try {
        dispatch(companyBannerUploadStart());
        const url = `${process.env.REACT_APP_ACCOUNT_URL}/api/PublicProfile/Banner?companyId=${companyPin}`;
        await fetch(url,
            {
                method: 'PUT',
                headers: {
                    //'Content-Type': 'multipart/form-data',
                    'Authorization': 'bearer ' + token
                },
                body
            }
        ).then(response => {
            if (response.status === 200) {
                dispatch(setCompanyBannerUploadedSuccessfully());
            }
            else {
                dispatch(companyProfileProcessingError("Could not upload banner"));
                dispatch(setErrorCode(response.status));
            }
        }).catch((error) => {
            dispatch(companyProfileProcessingError(error.message));
            dispatch(setErrorMessage(error.message));
        })
    }
    catch (error) {
        dispatch(companyProfileProcessingError(error.message));
        dispatch(setErrorMessage(error.message));
    }
}

export const fetchUserBusiness = (token: string): AppThunk => async dispatch => {
    try {
        dispatch(userProcessingStart());
        const url = `${process.env.REACT_APP_ACCOUNT_URL}/api/UserProfile/UserBusiness`;
        const results = await fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': 'bearer ' + token
            }
        }).then(response => {
            if (response.status === 200) {
                response.json().then(results => {
                    dispatch(setUserBusinessProfile(results));
                })
            }
            else {
                dispatch(userProcessingError("Could not get profile, please try again"));
                dispatch(setErrorCode(response.status));
            }
        }).catch((error) => {
            dispatch(userProcessingError(error.message));
            dispatch(setErrorMessage(error.message));
        })
    }
    catch (error) {
        dispatch(userProcessingError(error.message));
        dispatch(setErrorMessage(error.message));
    }
}
