import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import IDetailedProfile from "interfaces/IDetailedProfile";

interface ISearchState {
    searchProcessing: boolean,
    searchResults: Array<IDetailedProfile> | null,
    searchErrorMessage: string
}

let initialState : ISearchState = {
    searchProcessing: false,
    searchResults: null,
    searchErrorMessage: ''
}

const searchSlice = createSlice({
    name: 'search',
    initialState,
    reducers: {
        searchProcessingStart(state){
            state.searchProcessing = true;
            state.searchErrorMessage = '';
        },
        searchFound(state, { payload }: PayloadAction<Array<IDetailedProfile>>){
            state.searchProcessing = false;
            state.searchResults = payload;
            state.searchErrorMessage = '';
        },
        setSearchError(state, { payload }: PayloadAction<string>){
            state.searchProcessing = false;
            state.searchErrorMessage = payload;
        }
    }});

export const {
    searchProcessingStart,
    searchFound,
    setSearchError,
} = searchSlice.actions
    
export default searchSlice.reducer;