import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from 'app/rootReducer';

import { Button, CircularProgress, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Colors from 'Colors';
import { signIn, fetchUserBusiness } from './accountApi';
import { signOut, setOnBoarding } from './userSlice';
import OnBoardingMainView from './OnBoardingMainView';
import appRoutes from 'app/appRoutes';

interface Props {
    isMobilePhone: boolean
}
export default function SignOut({ isMobilePhone }: Props) {
    const dispatch = useDispatch();    
    let history = useHistory();
       
    const { userProcessing, isOnBoarding, userProcessingError, isAuthenticated, accessToken, userBusinessProfile } = useSelector( 
        (state: RootState) => state.user
    )

    useEffect(() => {
        if((isAuthenticated || accessToken) && !userProcessing) dispatch(signOut());
        if(isOnBoarding) dispatch(setOnBoarding(false));
        
        if(!isAuthenticated || !accessToken) {
            history.replace(appRoutes.signIn);
        }
    })
    
    return(
        <CircularProgress style={{ alignSelf: 'center', marginTop: 40 }} color="secondary" />  
    )
}