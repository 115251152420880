import React, { useEffect, useState } from 'react';

import { Grid, Card, CardMedia, CardContent, ButtonBase, makeStyles, Button, Box, CircularProgress } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { RootState } from 'app/rootReducer';
import appRoutes from 'app/appRoutes';

import Colors from 'Colors';

import { fetchProducts } from './tradeApi';
import { setCatalogueItem } from './tradeSlice';
import NewCatalogueItem from './NewCatalogueItem';
import HttpResponseMessages from 'features/errorHandling/HttpResponseMessages';
import IListing from 'interfaces/IListing';
import { AmountDisplayFormat } from 'utils/NumberFormatUtils';
import ProductsHeader from './ProductsHeader';

export default function Products() {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();

    const { accessToken } = useSelector(
        (state: RootState) => state.user
    )

    const { detailedProfile } = useSelector(
        (state: RootState) => state.profile
    )

    if(!detailedProfile) 
        window.location.replace(`${window.location.protocol == "https" ? "https" : "http"}://${window.location.host}`); //To do: relook

    const { listingItems, loadTradeProcessing, tradeErrorMessage } = useSelector(
        (state: RootState) => state.trade
    )

    useEffect(() => {
        const companyPin = window.location.search.substr(1);
        
        if (!listingItems && !loadTradeProcessing && !tradeErrorMessage)
        {
            if(companyPin) {
                dispatch(fetchProducts(companyPin));
            }
            else if(detailedProfile) { 
                dispatch(fetchProducts(detailedProfile?.profile.pin));
            }
        }
    }, [accessToken, detailedProfile, listingItems, loadTradeProcessing, tradeErrorMessage])

    interface IItemCardProps {
        item: IListing
    }
    const ItemCard = ({ item }: IItemCardProps) => {
        const discount = item.price * 0.17 < 50 ? item.price * 0.17 : 50;
        return (
            <ButtonBase onClick={() => history.push(`${appRoutes.productDetails}/${item.id}`)}>
                <Card variant="outlined" className={classes.cardContainer}>
                    <CardMedia
                        className={classes.thumbnail}
                        image={`data:image/jpeg;base64,${item.thumbnail}`}
                        title={item.alias}
                    />
                    <CardContent style={{ width: "100%" }}>
                        <Box fontSize={18}>{item.alias}</Box>
                        {item.price > 0 ? <Box display="flex" flexDirection="row" justifyContent="space-between" pl={2} pr={2} fontSize={17}>                      
                            <Box component="span" style={{ color: Colors.grey3, textDecoration: 'line-through' }}>
                                <AmountDisplayFormat displayCurrency>{item.price}</AmountDisplayFormat> {/* priceSale && fCurrency(priceSale) */} 
                            </Box>
                            <Box><AmountDisplayFormat displayCurrency>{(item.price - discount)}</AmountDisplayFormat></Box> 
                        </Box> : <Box pl={2} pt={1 * 0.5} style={{ color: Colors.grey3 }} fontSize={15}>Place Order</Box>}
                    </CardContent>
                </Card>
            </ButtonBase>
        )
    }

    const displayPicture = detailedProfile?.profile.logo ? `data:image/png;base64,${detailedProfile?.profile.logo}` : null;
    
    return (
        <Grid container className={classes.container}>
            {displayPicture && <Grid item xs={12}>    
                <ProductsHeader />
            </Grid>}
            <Grid item xs={12}>    
                <HttpResponseMessages />
                {loadTradeProcessing ? <CircularProgress /> : null}
            </Grid>
            <Grid item xs={12}>
                <h2 style={{ color: Colors.ivory2 }}></h2>
            </Grid>
            <Grid item xs={12}>
                <Grid container justify="center" spacing={2}>
                    {listingItems ? listingItems?.map((item, index) => (
                        <Grid key={index}>
                            <ItemCard item={item}></ItemCard>
                        </Grid>
                    )) : null}
                </Grid>
            </Grid>
        </Grid>
    )
}

const useStyles = makeStyles((theme) => ({  
    container: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        padding: theme.spacing(2),
    },
    cardContainer: {
        display: 'inline-flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        borderColor: Colors.ivory,
        margin: theme.spacing(1),
        backgroundColor: Colors.transparent,
        color: Colors.ivory2
    },
    thumbnail: {
        width: 320,
        height: 180,
    },
}));