import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from 'app/rootReducer';
import appRoutes from 'app/appRoutes';

import { makeStyles } from '@material-ui/core/styles';
import { Select, MenuItem, TextField, FormControl, FormHelperText, Typography, Button, CircularProgress } from '@material-ui/core';

import Colors from 'Colors';
import OnBoardingMainView from './OnBoardingMainView';

import { submitDetailedProfile, uploadCompanyBanner } from './accountApi';
import DetailedProfileModel from 'models/DetailedProfileModel';
import { openPopUpMessage } from 'app/appSlice';
import { setOnBoarding } from './userSlice';
import HttpResponseMessages from 'features/errorHandling/HttpResponseMessages';
import { clearErrorMessage } from 'features/errorHandling/errorHandlingSlice';

interface Props {
    isMobilePhone: boolean
  }

export default function DetailedProfile({ isMobilePhone } : Props){
    const classes = useStyles();   
    const dispatch = useDispatch();
    let history = useHistory();

    const [friendlyUserName, setFriendlyUserName] = useState("");
    const [motto, setMotto] = useState("");
    const [description, setDescription] = useState("");
    const [themeColor, setThemeColor] = useState("30, 30, 30");
    const [uploadPicture, setUploadPicture] = useState<any>({});    
    const [errorMessage, setErrorMessage] = useState("");
    
    const { isOnBoarding, accessToken } = useSelector( 
        (state: RootState) => state.user
    )
    
    const showPopupMessage = useSelector(
        (state: RootState) => state.app.showPopupMessage
    )

    const { companyProfileId, companyProfileProcessing, detailedProfileSubmittedSuccessfully, companyBannerUploadProcessing, companyBannerUploadedSuccessfully, companyProfileProcessingError } = useSelector( 
        (state: RootState) => state.companyProfile
    )

    const onSubmitClick = (e: any) => {
        e.preventDefault();
        dispatch(clearErrorMessage());
        setErrorMessage("");
        //Validate
        if(!friendlyUserName){
            setErrorMessage("Friendly Name is required for your public profile.");
            return;
        }
        else if(!description){
            setErrorMessage("Description is required for your public profile.");
            return;
        }        
        else if(!uploadPicture.name){
            setErrorMessage("Banner picture is required to be uploaded for your public profile.");
            return;
        }
        
        var modifyFriendlyUserName = friendlyUserName.indexOf("@") === -1 ? `@${friendlyUserName}` : friendlyUserName; 
        
        if(companyProfileId){
            const model = new DetailedProfileModel(companyProfileId, modifyFriendlyUserName, description.trim(), motto.trim(), themeColor);
    
            if(!companyProfileProcessing && !detailedProfileSubmittedSuccessfully) 
                dispatch(submitDetailedProfile(accessToken, model));
        }
        else{
            setErrorMessage("Company profile not found.");
        }
    }
        
    if(companyProfileId && detailedProfileSubmittedSuccessfully && !companyBannerUploadProcessing && !companyBannerUploadedSuccessfully) 
        dispatch(uploadCompanyBanner(accessToken, uploadPicture, companyProfileId));

    if(!companyProfileProcessing && detailedProfileSubmittedSuccessfully && !showPopupMessage) { 
        dispatch(openPopUpMessage("Business information updated"));  
        /* dispatch(openPopUpMessage("Start creating your profile by listing the items (product/service) you offer"));   */  
        // if(isOnBoarding) dispatch(setOnBoarding(false));   
        history.replace(appRoutes.listItems);
    }

    return(
        <OnBoardingMainView isMobilePhone={isMobilePhone} heading="Business Details">
            <p className="Sub-header" style={{ color: Colors.grey1 }}></p>
            <form onSubmit={onSubmitClick} className={classes.container}>
                <TextField
                    label="Friendy Username '@'"
                    variant="outlined"
                    className={classes.input}
                    onChange={(e) => setFriendlyUserName(e.target.value.trim())}
                    value={friendlyUserName}
                />
                <TextField
                    multiline={true}
                    rows={2}
                    label='Motto'
                    variant="outlined"
                    className={classes.input}
                    onChange={(e) => setMotto(e.target.value)}
                    value={motto}
                />
                <TextField
                    multiline={true}
                    rows={3}
                    label='Description'
                    variant="outlined"
                    className={classes.input}
                    onChange={(e) => setDescription(e.target.value)}
                    value={description}
                />
                <FormControl variant="outlined">
                    <FormHelperText style={{ color: 'lightgray' }}>Theme color</FormHelperText>
                    <Select
                        className={classes.input}
                        value={themeColor}
                        onChange={e => setThemeColor(e.target.value as string)} >
                        <MenuItem value={"30, 30, 30"}>Black</MenuItem>
                        <MenuItem value={"10, 21, 1"}>Dark Green</MenuItem>
                    </Select>
                </FormControl>
                <FormControl variant="outlined" style={{ marginTop: 10 }}>
                    <FormHelperText style={{ color: 'lightgray' }}>Upload Banner</FormHelperText>
                    <input
                        type="file"
                        accept="image/gif, image/jpeg, image/jpg, image/png"
                        className={classes.fileInput}
                        onChange={(e) => setUploadPicture(e.target.files ? e.target.files[0] : {})}
                    />
                </FormControl>
                <Button
                    disabled={companyProfileProcessing || companyBannerUploadProcessing}
                    variant="outlined"
                    className={classes.button}
                    type="submit">
                    Submit
                </Button>
                {companyProfileProcessing || companyBannerUploadProcessing ? <CircularProgress color="secondary" /> : null}               
                <p className={classes.errorMessage}>{errorMessage}</p> 
                <HttpResponseMessages showInPopup={true} />
            </form>                           
        </OnBoardingMainView>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      color: Colors.ivory,
      alignItems: 'center',
      borderRadius: 2,
      marginBottom: 0,
    },
    input: {
      width: 222,
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(1 * 0.5),
      marginRight: theme.spacing(1 * 0.5)
    },
    fileInput: {
      width: 222,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
      marginLeft: theme.spacing(1 * 0.5),
      marginRight: theme.spacing(1 * 0.5)
    },
    button: {
        width: 150,
        margin: theme.spacing(2),
    },
    errorMessage: {
        color: Colors.maroon, 
        fontSize: 15,
        padding: theme.spacing(1)
    }
}));