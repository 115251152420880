import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from 'app/rootReducer';
import appRoutes from 'app/appRoutes';

import KbLogo from 'img/logo_secured.png';

import { Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Colors from 'Colors';

import { CupertinoPane } from 'cupertino-pane';

import PayMainView from './PayMainView';

import EnterCardDetails, { DisplayCardInfo } from './EnterCardDetails';
import Authorise3D from './Authorise3D';

import { submitPayment } from './paymentApi';
import { setAcquisition } from 'features/trade/tradeSlice';
import PaymentModel from 'models/PaymentModel';
import ICard from 'interfaces/ICard';

import PayGateLogo from 'img/PayGate_DPO_Company.png';
import VisaLogo from 'img/PayGate-Payment-Method-Logo-Visa.png';
import MasterCardLogo from 'img/PayGate-Payment-Method-Logo-Mastercard.png';

import { AmountDisplayFormat } from 'utils/NumberFormatUtils';
import HttpResponseMessages from 'features/errorHandling/HttpResponseMessages';
import { clearErrorMessage } from 'features/errorHandling/errorHandlingSlice';

interface Props {
    isMobilePhone: boolean
}
export default function SubmitPayment({ isMobilePhone }: Props) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const history = useHistory();

    const { accessToken, isAuthenticated } = useSelector(
        (state: RootState) => state.user
    )

    const { detailedProfile } = useSelector(
        (state: RootState) => state.profile
    )

    const { paymentProcessing, paymentResponseHtml, paymentProcessingError } = useSelector(
        (state: RootState) => state.payment
    )

    const acquisition = useSelector(
        (state: RootState) => state.trade.acquisition
    )

    let myPane: CupertinoPane | null = null;    
    useEffect(() => {
        if(!acquisition) if(!detailedProfile)  
            window.location.replace(`${window.location.protocol == "https" ? "https" : "http"}://${window.location.host}`); //history.push(appRoutes.home);
        if (!isAuthenticated) {
            history.push(appRoutes.paymentUserInfo);
        } 

        if (isAuthenticated && !myPane) {
            myPane = new CupertinoPane(
                '.cupertino-pane', // Pane container selector
                {
                    parentElement: 'div', // Parent container
                    breaks: {
                        middle: { enabled: true, height: 210, bounce: true },
                        bottom: { enabled: true, height: 150 },
                    },
                    onDrag: () => console.log('Drag event'),
                    cssClass: 'cupertino-pane-container'
                }
            );
        }

        if (myPane) myPane.present({ animate: true }).then(res => { console.log(res) });
        //if(userBusinessProfile && !paymentProcessing && !paymentCompletedSuccessfully && !paymentProcessingError) 
        //processPayment();   
    }, [])

    const [cardNumber, setCardNumber] = useState("");
    const [cardExpiryMonth, setCardExpiryMonth] = useState("");
    const [cardExpiryYear, setCardExpiryYear] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const InfoDisplay = () => {
        return (
            <div>
                {cardNumber &&
                    <DisplayCardInfo cardNumber={cardNumber} cardExpiryMonth={cardExpiryMonth} cardExpiryYear={cardExpiryYear} />
                }
                <div>Make secured payment to:</div>
                {detailedProfile?.profile &&
                    <div>
                        <img src={`data:image/jpg;base64,${detailedProfile.profile.logo}`} className={classes.companyLogo} alt="" />
                        <div style={{ fontSize: 12 }}>{detailedProfile.profile.tradingName}</div>
                    </div>
                }
                <div style={{ fontSize: 12, marginTop: 10 }}>
                    <strong>Notes: </strong>
                    <em>{acquisition?.comment}</em>
                </div>
                {acquisition && <div style={{ fontSize: 12, marginBottom: 5 }}>
                    Amount: <AmountDisplayFormat displayCurrency>{acquisition?.offer * acquisition?.quantity}</AmountDisplayFormat>
                </div>}
                <div className={classes.logosContainer}>
                    <div>
                        <div style={{ fontSize: 11, marginBottom: 5 }}>Supported cards</div>
                        <img src={VisaLogo} style={{ width: 50, marginRight: 10 }} />
                        <img src={MasterCardLogo} style={{ width: 30 }} />
                    </div>
                    <div style={{ fontSize: 11, marginLeft: 10 }}>
                        <div>Payment gateway</div>
                        <img src={PayGateLogo} width="100px" alt="Paygate" />
                    </div>
                </div>
                {isMobilePhone && <img src={KbLogo} style={{ width: 125, marginTop: 30 }} alt="KhonaBiz" />}
            </div>
        )
    }

    const processPayment = (cardDetails: ICard) => {
        dispatch(clearErrorMessage());

        if (acquisition == null || detailedProfile == null) {
            setErrorMessage('No payment information');
            return;
        }
        if (cardDetails == null) {
            setErrorMessage('Invlaid card information');
            return;
        }

        var paymentModel = new PaymentModel(accessToken, acquisition?.comment, cardDetails, acquisition.offer * acquisition.quantity, detailedProfile?.profile.pin, acquisition.type, acquisition.item);

        dispatch(submitPayment(accessToken, paymentModel));
    }

    const cancelPayment = () => {
        dispatch(setAcquisition(null));
        if(myPane) myPane.destroy();
        if(!detailedProfile)  window.location.replace(`${window.location.protocol == "https" ? "https" : "http"}://${window.location.host}`); //history.replace(appRoutes.home);
    }

    return (
        <div>
            <PayMainView isMobilePhone={isMobilePhone}>
                <div className={isMobilePhone ? "cupertino-pane" : classes.infoContainer}>
                    <InfoDisplay />                    
                    <Button
                        style={{ color: Colors.orange2 }}
                        onClick={cancelPayment}>
                        Cancel
                    </Button>
                </div>
                <div className="pay-container" style={!isMobilePhone ? { width: 350 } : { width: '100%' }}>
                    {errorMessage && <div className={classes.errorMessage}>{errorMessage}</div>}
                    <HttpResponseMessages />
                    {!paymentProcessing && !paymentResponseHtml && <EnterCardDetails
                        handleCardDetails={(card) => processPayment(card)}
                        handleDisplayCardNumber={(cardNumber) => setCardNumber(cardNumber)}
                        handleDisplayExpiryMonth={(expiryMonth) => setCardExpiryMonth(expiryMonth)}
                        handleDisplayExpiryYear={(expiryYear) => setCardExpiryYear(expiryYear)} />}
                    {paymentProcessing && <CircularProgress style={{ alignSelf: 'center', marginTop: 40 }} color="secondary" />}
                    {(!paymentProcessing && paymentResponseHtml) && <Authorise3D html={paymentResponseHtml} />}
                    <Button
                        style={{ color: Colors.grey4 }}
                        onClick={cancelPayment}>
                        Cancel
                    </Button>
                </div>
            </PayMainView>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    infoContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        maxWidth: 550,
        height: 450,
        backgroundColor: Colors.ivory,
        color: Colors.grey1,
        flexGrow: 1,
        padding: theme.spacing(1)
    },
    companyLogo: {
        height: 50,
        width: 120,
        objectFit: 'contain'
    },
    logosContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        margin: 20,
        marginTop: 15
    },
    errorMessage: {
        color: Colors.orangeRed,
        fontSize: 14
    }
}));