import { AppThunk } from 'app/store';
import { setErrorCode, setErrorMessage } from 'features/errorHandling/errorHandlingSlice';

import { searchProcessingStart, searchFound, setSearchError } from './searchSlice';

export const fetchSearchCompaniesByKey = (key: string): AppThunk => async dispatch => {
    try {
        dispatch(searchProcessingStart());
        const url = `${process.env.REACT_APP_ACCOUNT_URL}/api/PublicProfile/Search?key=${key}`;
        await fetch(url,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            }).then(response => {
                if (response.status === 200) {
                    response.json().then(results => {
                        dispatch(searchFound(results));
                    })
                }
                else {
                    dispatch(setSearchError("Could not load Items"));
                    dispatch(setErrorCode(response.status));
                }
            })
            .catch((error) => {
                dispatch(setSearchError(error.message));
                dispatch(setErrorMessage(error.message));
            });
    }
    catch (error) {
        dispatch(setSearchError(error.message));
        dispatch(setErrorMessage(error.message));
    }
}
