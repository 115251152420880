import React from 'react';

import { makeStyles } from '@material-ui/core';

import Colors from 'Colors';

interface Props {
    password: string,
    setIsPasswordValid: (isPasswordValid: boolean) => void,
}
export default function PasswordStrengthIndicator({ password, setIsPasswordValid }: Props) {  
    const classes = useStyles();
    let statusText = '';
    let statusColor = Colors.white;
    let strengthPoints = 0;

    if(password.length == 0) return(<div></div>);

    //if(password.length > 0) strengthPoints++;
    
    if(password.length > 7) strengthPoints++;
    
    if(/[a-z]/.test(password)) strengthPoints++;          
    
    if(/[A-Z]/.test(password)) strengthPoints++;          
    
    if(/[0-9]/.test(password)) strengthPoints++;
    
    if(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(password)) strengthPoints++;
    
    if(strengthPoints >= 5){
        setIsPasswordValid(true);
    }
    else{
        setIsPasswordValid(false);
    }

    switch(strengthPoints) {
        case 1:
            statusText = 'Weak';
            statusColor = Colors.maroon;
            break;
        case 2:
            statusText = 'Getting there';
            statusColor = Colors.orange2;  
            break;          
        case 3:
            statusText = 'Keep going';
            statusColor = Colors.lightBlue1;  
            break;
        case 4:
            statusText = 'Almost'
            statusColor = Colors.lightGreen;
            break;
        case 5:
            statusText = 'Accepted';
            statusColor = Colors.primaryColor;   
            break;
    }

    var createLine = []; 
    for(var i = 0; i <= strengthPoints; i++) {
        createLine.push(<div className={classes.indicator} style={{backgroundColor: statusColor}} key={i}></div>);
    }

    return (
        <div style={{margin: 10}}>
            <div className={classes.container}>
                {createLine}
            </div>
            <p className={classes.statusText} style={{ color: statusColor }}>{statusText}</p>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        borderRadius: 50
    },
    indicator: {
        width: 30,
        height: 5
    },
    statusText: { 
        margin: 0,
        fontSize: 14
    }
}));