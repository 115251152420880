import React from 'react';

interface Props {
    html: string
}
export default function Authorise3D({ html }: Props){
    return(
        <div dangerouslySetInnerHTML={{__html: html}}>        
        </div>
    )
}