import ICatalogueItem from "interfaces/ICatalogueItem";
import IItemCategory from "interfaces/IItemCategory";

export default class CatalogueItemModel implements ICatalogueItem{
    id!: string;
    description: string;
    selectedCategory: IItemCategory;
    selectedBusinessType!: string;
    businessType!: string;
    category!: string;
    isActive: boolean;
    
    constructor(description: string, selectedCategory: IItemCategory){
        this.description = description;
        this.selectedCategory = selectedCategory;
        this.isActive = true;
    }
}