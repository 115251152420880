import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';

import logo from '../../img/logo2.png';
import Colors from 'Colors';
import { ButtonBase } from '@material-ui/core';
import { useHistory } from 'react-router';
import appRoutes from 'app/appRoutes';

const GlobalCss = withStyles({
  // @global is handled by jss-plugin-global.
  '@global': {
    // You should target [class*="MuiButton-root"] instead if you nest themes.
    '.MuiButton-outlined': {
      color: Colors.ivory,
      borderRadius: 10,
      border: 'solid 2px transparent',
      background: `linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, ${Colors.lightGreen1}, ${Colors.lime})`,
      backgroundOrigin: 'border-box',
      boxShadow: `2px 1000px 1px ${Colors.grey1} inset`
    },
    '.MuiOutlinedInput-root': {  
      color: Colors.ivory, 
        '& fieldset': { 
          borderColor: Colors.wheat,
        },
        '&:hover fieldset': {
          borderColor: `${Colors.yellowgreen} !important`,
        },
        '&.Mui-focused fieldset': {
          borderColor: `${Colors.primaryColor} !important`,
          color: `${Colors.ivory} !important`
        }
    },
    '.MuiOutlinedFormControl-root': {  
      '& fieldset': { 
          borderColor: Colors.ivory,
        },
    },
    '.MuiInputLabel-root': {   
      color: Colors.ivory,  
      '&.Mui-focused': {
        color: `${Colors.ivory} !important`
      }  
    }
  },
})(() => null);

interface Props {
  isMobilePhone: boolean,
  heading: string
}
export default function OnBoardingMainView(props: React.PropsWithChildren<Props>) {
  const classes = useStyles();
  const history = useHistory();

    return (
      <div className="App-body" style={{ minHeight: '100vh' }}>
        <GlobalCss />
        <div className={classes.container} style={ props.isMobilePhone ? { minHeight: '100vh' } : { minHeight: 620 }}>
          <div className={classes.logoContainer}>
            <ButtonBase onClick={() => history.push(appRoutes.home)}>
              <img
                className={classes.mainLogo}
                src={logo} />
            </ButtonBase>
            <p className={classes.heading}>{props.heading}</p>
          </div>
          <div className={classes.contentContainer}>
              {props.children}    
          </div>
        </div>
      </div>
    );
}

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    maxWidth: 500,  
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: Colors.secondaryColor, //'#ef9e13',
    paddingBottom: 5
  },
  logoContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%', 
    textAlign: 'left',
    marginBottom: theme.spacing(3),  
  },
  mainLogo: {
    width: 140, 
    height: 70, 
    margin: 0,
    padding: 0
  },
  heading: {
    width: '100%',
    justifyContent: 'center',
    textAlign: 'center',
    fontSize: 18
  },
  contentContainer: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    marginTop: -50
  },
}));