import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import IErrorMessage from 'interfaces/IErrorMessage';

interface IPaymentState {
    errorMessage: string;
    errorCode: number;
    httpAction: string;
}

let initialState: IPaymentState = {
    errorMessage: "",
    errorCode: -1,
    httpAction: ""
}

const errorHadlingSlice = createSlice ({
    name: 'errorHandling',
    initialState,
    reducers: {
        setError(state, { payload }: PayloadAction<IErrorMessage>) {
            state.errorMessage = payload.message;
            state.errorCode = payload.code;
            state.httpAction = payload.action;
        },
        setErrorCode(state, { payload }: PayloadAction<number>) {
            state.errorCode = payload;
        },
        setErrorMessage(state, { payload }: PayloadAction<string>) {
            state.errorMessage = payload;
        },
        clearErrorMessage(state) {
            state.errorMessage = "";
            state.errorCode = -1;
            state.httpAction = "";
        }
    }
});

export const {
    setError,
    setErrorCode,
    setErrorMessage,
    clearErrorMessage
} = errorHadlingSlice.actions;

export default errorHadlingSlice.reducer;