import { AppThunk } from "app/store";

import { setError, setErrorCode, setErrorMessage } from "features/errorHandling/errorHandlingSlice";
import { paymentProccesingStart, setPaymentCompletedSuccessfully, set3DAuthorise, setPaymentProcessingError } from "features/payment/paymentSlice";

import IPayment from "interfaces/IPayment";
import ErrorMessageModel from "models/ErrorMessageModel";

export const submitPayment = (token: string, data: IPayment): AppThunk => async dispatch => {
    try {
        dispatch(paymentProccesingStart());
        const url = `${process.env.REACT_APP_PAY_URL}/api/Pay`;
        await fetch(url,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'bearer ' + token
                    },
                    body: JSON.stringify(data),
                }
            ).then(response => {        
                if(response.status === 200){
                    response.json().then(results => {
                        dispatch(set3DAuthorise(results));
                    })    
                }      
                else {
                    dispatch(setPaymentProcessingError("Could not process payment, please try again")); 
                    
                    let error = new ErrorMessageModel(response.status, "Processing Payment");
                    dispatch(setError(error));
                }
            }).catch((error) => {
                dispatch(setPaymentProcessingError(error.message));
                dispatch(setErrorMessage(error.message));
            })
        
        dispatch(setPaymentCompletedSuccessfully());
    }
    catch (error) {
        dispatch(setPaymentProcessingError(error.message));
        dispatch(setErrorMessage(error.message));
    }
}