import React from 'react';
import logo from 'img/logo.png';
import { Link } from 'react-router-dom';
import appRoutes from './appRoutes';

import Colors from 'Colors';

interface Props {
  businessName: string | undefined,
  userDisplayName: string | undefined
}
export default function AppAdminHeader({ businessName, userDisplayName }: Props){
    return(
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <div style={{ fontSize: 22 }}>{businessName}</div>
          <div><Link to={appRoutes.signOut} style={{ color: Colors.ivory }}>sign-out</Link>, {userDisplayName}</div>         
        </header>
    )
}