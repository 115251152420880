import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import appRoutes from 'app/appRoutes';
import { RootState } from 'app/rootReducer';

import logo from '../../img/logo3.png';
import dpPlaceholder from 'img/placeholders_logo.jpg';
import bannerPlaceholder from 'img/placeholders_banner.jpg';

import { withStyles } from '@material-ui/core/styles';
import { Box, Button, ButtonBase, ButtonGroup, IconButton, Link } from '@material-ui/core';
import { Phone, Email } from '@material-ui/icons';

import Colors from 'Colors';

import Enquiry from 'features/trade/Enquiry';
import Acquisition from 'features/trade/Acquisition';

const GlobalCss = withStyles({
    // @global is handled by jss-plugin-global.
    '@global': {
      // You should target [class*="MuiButton-root"] instead if you nest themes.
      '.MuiButton-outlined': {
        color: Colors.ivory,
        borderRadius: 10,
        border: 'solid 2px transparent',
        background: `linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, ${Colors.secondaryColor}, ${Colors.lime})`,
        backgroundOrigin: 'border-box',
        boxShadow: `2px 1000px 1px ${Colors.grey1} inset`
      },
      '.MuiOutlinedInput-root': {  
        color: Colors.ivory, 
        '& fieldset': { 
            borderColor: Colors.secondaryColor,
          },
      },
      '.MuiOutlinedFormControl-root': {  
        '& fieldset': { 
            borderColor: Colors.secondaryColor,
          },
      },
      '.MuiInputLabel-root': {   
        color: Colors.ivory,    
      }
    },
  })(() => null);

interface Props {
    profileLinksIcons: Record<string, any>,
    ratingStars: Array<any>,
    isMobilePhone: boolean,
    isCustomDomain: boolean
    goToHome: () => void
}

export default function ProfileMobile({ profileLinksIcons, ratingStars, isMobilePhone, isCustomDomain, goToHome } : Props){
    require('./ProfileMobile.css');
    const history = useHistory();
        
    const { detailedProfile, profileBanner, catalogueCategories, profileLinks } = useSelector(
        (state: RootState) => state.profile
    )

    const [makePayment, setMakePayment] = useState(false);
    
    const displayPicture = detailedProfile?.profile.logo ? `data:image/png;base64,${detailedProfile?.profile.logo}` : dpPlaceholder;
    const banner = profileBanner && profileBanner?.length > 0 ? `data:image/png;base64,${profileBanner[0].media}` : bannerPlaceholder;
    const descriptionMaxLength = 100;

    const iconLinks = profileLinks?.filter(item => item.showAs === 0);
    const otherLinks = profileLinks?.filter(item => item.showAs !== 0);

    return(
        <div className="Root"> 
            <GlobalCss />           
            <header className="Profile-header-mobile">
                <img src={displayPicture} className="Display-picture-mobile" alt={detailedProfile?.profile.tradingName} />
                <div className="Display-name-mobile">{detailedProfile?.profile.tradingName}</div>
            </header>
            <div className="Banner-mobile">
                <img src={banner} className="Banner-picture-mobile" alt="" />
            </div>
            <div className="Details-container-mobile">
                {detailedProfile && detailedProfile?.profile.companyType >= 10 && detailedProfile?.profile.companyType <= 25 && <Box>
                    <Button variant="text" color="primary" className="Action-button-mobile"
                        onClick={() => history.push(appRoutes.products)}>
                            {detailedProfile?.actionButtonText ? detailedProfile?.actionButtonText : "Shop Items"}
                    </Button>
                </Box>}
                <div className="Details-left-container-mobile">
                    <div className="Details-left-inner-container-mobile">
                        <strong style={{ fontSize: 12 }}>{detailedProfile?.tagLine}</strong>
                        {otherLinks && <div className="Other-links-container-mobile">
                            {otherLinks?.map((item, index) => (
                                item.showAs === 2 ? 
                                    <Button target="_blank" variant="outlined" color="primary" href={item.link} key={index} style={{ minWidth: 265 }}>{item.name}</Button> : 
                                    <Link target="_blank" color="primary" href={item.link} key={index} style={{ minWidth: 265 }}>{item.name}</Link>
                            ))}
                        </div>}
                        {detailedProfile?.description}                     
                    </div>
                    <div className="Services-list-mobile">
                        {catalogueCategories && catalogueCategories.length > 0 && <p className="Sub-header-mobile">
                            {detailedProfile?.catalogueCategoriesHeading ? detailedProfile.catalogueCategoriesHeading : "Products/Services"}
                        </p>}
                        <ul>
                            {catalogueCategories?.map((item, index) => (
                                <li key={index}>{item}</li>
                            ))}
                        </ul>
                    </div>
                    <div className="Contacts-container-mobile">
                        <div className="Platforms-container-mobile">
                            {iconLinks ? iconLinks.map((item, index) => (                                
                                <IconButton 
                                    key={index} aria-label="upload picture" 
                                    component="span"
                                    onClick={() => window.open(item.link, "_blank")}>
                                    {profileLinksIcons[item.name]}
                                </IconButton>
                            )) : null}
                        </div>
                        {detailedProfile ? <div className="Contact-info-mobile">
                            <div><Phone /><span>{detailedProfile.profile.contacts}</span></div>
                            <div><Email /><span>{detailedProfile.profile.email}</span></div>
                        </div> : null}
                    </div>
                </div>
                <div className="Details-right-container-mobile">
                    {/* <ButtonGroup color="primary" className="Button" aria-label="split button">
                        <Button 
                            variant="contained" 
                            disableElevation  
                            size="large" 
                            disabled={!makePayment}
                            onClick={() => setMakePayment(false)}>
                                {detailedProfile?.actionButtonText ? detailedProfile?.actionButtonText : "Enquire"}
                        </Button>
                        <Button
                            variant="contained"                                   
                            size="large"
                            disabled={makePayment} 
                            onClick={() => setMakePayment(true)}>
                                PAY
                        </Button>
                    </ButtonGroup> */}
                    {detailedProfile && catalogueCategories && !makePayment ? 
                        <Enquiry 
                            heading={detailedProfile.actionButtonText ? detailedProfile?.actionButtonText : "Enquire"}
                            visitId={detailedProfile.visitId}
                            services={catalogueCategories}
                            isMobilePhone={isMobilePhone} /> : null}
                    {detailedProfile && makePayment ? 
                        <Acquisition 
                            heading={"Acquire Service / Product"}
                            companyPin={detailedProfile.profile.pin}
                            emailAddress={detailedProfile.profile.email}
                            isMobilePhone={isMobilePhone} /> : null}
                </div>
            </div>
            <div className="Footer-container-mobile">
                <div className="Footer-mobile">
                    {ratingStars && ratingStars.length > 0 && <div className="Ratings-container-mobile">
                        <div className="Ratings-mobile">
                            {ratingStars.map((item) => (
                                item
                            ))}
                        </div>
                    </div>}
                </div>
                {!isCustomDomain && <ButtonBase className="Logo-button-mobile" onClick={goToHome}>
                    <img src={logo} style={{ height: 50 }} alt="KhonaBiz" />
                </ButtonBase>}
            </div>
        </div>
    )
}